import React from "react";
import {
  CHECK_ICON,
  PROJECT_DETAILS01,
  PROJECT_DETAILS02,
} from "../../lib/assets";

const ProjectDetails = () => {
  return (
    <>
      {/* project-details-area */}
      <section className="project-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
          <div className="section-title mb-30">
          <span className="sub-title">Historia</span>
                <h2 className="title">
                Calibrando sueños desde 1992
                </h2>
              </div>
            <div className="col-12">
              <div className="project-details-wrap">
                <div className="row">
                  {/* image */}
                  <div className="col-71">
                    <div className="project-details-thumb">
                      <img src={PROJECT_DETAILS01} alt="" />
                    </div>
                  </div>

                  {/* project details info */}
                  <div className="col-29">
                    <div className="project-details-info">
                      <h4 className="title">Información de relevante</h4>
                      <ul className="list-wrap">
                        <li>
                          <span>Razón Social:</span>
                          CalTechnix de México S.A. de C.V.
                        </li>
                        <li>
                          <span>Fundación:</span>
                          1992
                        </li>
                        <li>
                          <span>Servicios:</span>
                          Calibración de Presión, Fuerza
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* CONTENIDO PRINCIPAL */}
                <div className="project-details-content">
                  <h2 className="title">Historia y enfoque</h2>
                  <p>
                    CalTechnix inició operaciones en 1992 con el propósito 
                    de ofrecer soluciones de calibración. Sus fundadores, 
                    con gran experiencia en metrología, han desarrollado 
                    servicios avalados hoy por acreditaciones EMA dentro 
                    del acuerdo ILAC.
                  </p>
                  <p>
                    A través de nuestro software <strong>AdCalib</strong>, 
                    aseguramos la trazabilidad de calibraciones y el 
                    cumplimiento de la norma ISO 17025. Seguimos apostando 
                    por la innovación, incorporando funciones de 
                    automatización que incrementan la confiabilidad de 
                    nuestros servicios.
                  </p>

                  <div className="pd-optimized-wrap">
                    <h3 className="title-two">Código de ética</h3>
                    <p>
                      Nuestro código de ética se basa en la integridad, 
                      imparcialidad y el compromiso con la calidad. 
                      Promovemos el respeto a los derechos humanos, la 
                      protección de la información y la responsabilidad 
                      social en cada paso que damos.
                    </p>
                  </div>

                  <div className="pd-inner-wrap">
                    <div className="row align-items-center">
                      <div className="col-41">
                        <div className="content">
                          <h3 className="title-two">
                            Filosofía de trabajo
                          </h3>
                          <p className="info-one">
                            Nos guían cuatro pilares:
                            <strong> Cliente, Calidad, Responsabilidad y 
                            Satisfacción</strong>. Creemos en forjar 
                            relaciones duraderas, brindar mediciones 
                            precisas y cumplir con los más altos 
                            estándares de gestión.
                          </p>
                          <ul className="list-wrap">
                            <li>
                              <img src={CHECK_ICON} alt="" />
                              Veracidad de las mediciones
                            </li>
                            <li>
                              <img src={CHECK_ICON} alt="" />
                              Competitividad y mejora continua
                            </li>
                            <li>
                              <img src={CHECK_ICON} alt="" />
                              Protección del medio ambiente
                            </li>
                            <li>
                              <img src={CHECK_ICON} alt="" />
                              Reconocimiento internacional
                            </li>
                          </ul>
                          <p className="info-two">
                            Gracias a la dedicación de nuestro equipo, 
                            CalTechnix se posiciona como referencia en 
                            servicios de calibración, cumpliendo 
                            objetivos de calidad y generando beneficios 
                            reales para nuestros clientes.
                          </p>
                        </div>
                      </div>

                      <div className="col-59">
                        <div className="thumb">
                          <img src={PROJECT_DETAILS02} alt="caltechnix" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIN pd-inner-wrap */}
                </div>
                {/* FIN project-details-content */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectDetails;
