// src/pages/ServiceOrder/ServiceOrderDetail.jsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FacturacionStep from "../../components/ServiceOrder/FacturacionStep";
import InstrumentosStep from "../../components/ServiceOrder/InstrumentosStep";
import CertificadoPreviewStep from "../../components/ServiceOrder/CertificadoPreviewStep";
import EncuestaCalidadStep from "../../components/ServiceOrder/EncuestaCalidadStep";
import { Layout } from "../../layouts/Layout";

const ServiceOrderDetailTwo = () => {
  const { idOrden } = useParams();
  const [step, setStep] = useState(1);
  const [datosFacturacion, setDatosFacturacion] = useState(null);
  const [instrumentos, setInstrumentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const [showBanner, setShowBanner] = useState(true);
  const [closingBanner, setClosingBanner] = useState(false);
  const BACKEND_URL = process.env.PUBLIC_BACKEND_URL || "";

  useEffect(() => {
    if (!idOrden) return;

    const fetchData = async () => {
      setLoading(true);
      setErrorMsg("");

      try {
        // 1) Factura
        const respFact = await fetch(
          `${BACKEND_URL}/api/datos-certificado`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ idOrden }),
          }
        );
        const dataFact = await respFact.json();

        if (!respFact.ok || dataFact.error) {
          setErrorMsg(dataFact.error || "Error al obtener datos de Facturación");
          setLoading(false);
          return;
        }
        setDatosFacturacion(dataFact);

        // 2) Instrumentos
        const respInst = await fetch(
          `${BACKEND_URL}/api/datos-certificado`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ idOrden }),
          }
        );
        const dataInst = await respInst.json();
        if (!respInst.ok || dataInst.error) {
          setErrorMsg(
            dataInst.error || "Error al obtener instrumentos de Calibración"
          );
          setLoading(false);
          return;
        }
        setInstrumentos(dataInst.data || []);
      } catch (error) {
        console.error(error);
        setErrorMsg("Error de conexión");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [idOrden]);

  const handleCloseBanner = () => {
    setClosingBanner(true);
    setTimeout(() => setShowBanner(false), 500);
  };

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <p className="text-gray-600">Cargando información...</p>
      </div>
    );
  }

  if (errorMsg) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <p className="text-red-500">{errorMsg}</p>
      </div>
    );
  }

  return (
    <Layout header={2} footer={1}>
      {/* BANNER / ANUNCIO */}
      {showBanner && (
        <div className="banner-backdrop">
          <div className={`banner-content ${closingBanner ? "fade-out" : ""}`}>
            <h1 className="text-2xl font-bold text-red-500 mb-4">
              ¡IMPORTANTE!
            </h1>
            <p className="text-gray-700 mb-4">
              Los datos siguientes se usan para el procesamiento de su calibración
              y los datos aparecerán así en el certificado de calibración.
            </p>
            <p className="text-gray-700 mb-4 font-semibold">
              Para evitar ambigüedades y confusiones, no se tomará en cuenta la
              información proporcionada en otro momento.
            </p>
            <p className="text-gray-700 mb-4">
              De no contar con su respuesta en un plazo de 48 horas como máximo, se
              iniciará el servicio con la información especificada en este formato,
              y cualquier modificación posterior podrá generar costos adicionales.
            </p>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
              onClick={handleCloseBanner}
            >
              He leído y acepto iniciar el proceso
            </button>
          </div>
        </div>
      )}

      <div className="h-[660px] bg-gray-50 p-8 flex gap-6 rounded-lg service-order-bg">
        {/* Sidebar */}
        <div className="w-64 bg-white rounded-2xl shadow-xl p-6 h-full service-order-rounded flex flex-col">
          <h2 className="text-xl font-bold mb-4 text-gray-700">Pasos</h2>
          <div className="flex-1 flex flex-col space-y-2 overflow-y-auto">
            <button
              onClick={() => setStep(1)}
              className={`w-full text-left py-2 px-3 rounded-xl transition-colors ${
                step === 1
                  ? "bg-blue-100 text-blue-700 font-semibold"
                  : "hover:bg-gray-100 text-gray-600"
              }`}
            >
              1. Facturación
            </button>

            <button
              onClick={() => setStep(2)}
              className={`w-full text-left py-2 px-3 rounded-xl transition-colors ${
                step === 2
                  ? "bg-blue-100 text-blue-700 font-semibold"
                  : "hover:bg-gray-100 text-gray-600"
              }`}
            >
              2. Certificado de Calibración
            </button>

            <button
              onClick={() => setStep(3)}
              className={`w-full text-left py-2 px-3 rounded-xl transition-colors ${
                step === 3
                  ? "bg-blue-100 text-blue-700 font-semibold"
                  : "hover:bg-gray-100 text-gray-600"
              }`}
            >
              3. Vista Previa
            </button>

            <button
              onClick={() => setStep(4)}
              className={`w-full text-left py-2 px-3 rounded-xl transition-colors ${
                step === 4
                  ? "bg-blue-100 text-blue-700 font-semibold"
                  : "hover:bg-gray-100 text-gray-600"
              }`}
            >
              4. Encuesta de Calidad
            </button>
          </div>

          {/* Botones de navegación */}
          <div className="pt-4 border-t border-gray-100 flex flex-col space-y-3">
            {step > 1 && (
              <button
                onClick={handlePrevStep}
                className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded-xl"
              >
                Regresar
              </button>
            )}

            {step < 4 && (
              <button
                onClick={handleNextStep}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl"
              >
                Siguiente
              </button>
            )}
          </div>
        </div>

        {/* Contenedor de la columna derecha */}
        <div className="flex-1 h-full service-order-rounded overflow-hidden">
          {/*
            Aquí dividimos en dos contenedores:
            1) Encabezado sticky (ID de Orden + texto)
            2) Contenido scrolleable (los pasos)
          */}
          <div className="bg-white service-order-rounded shadow-xl h-full flex flex-col">
            {/* Encabezado sticky */}
            <div className="sticky top-0 z-10 bg-white px-8 pt-8">
              <h1 className="text-2xl font-bold text-gray-700 mb-2">
                ID de Orden:{" "}
                <span className="text-blue-600">
                  {datosFacturacion?.OrdenServicio || idOrden}
                </span>
              </h1>
              <p className="text-sm text-gray-500 mb-4">
                Los datos siguientes se usarán para realizar la factura de su
                servicio, por lo que le solicitamos verifique cuidadosamente.
              </p>
              {/* Podrías colocar una ligera sombra para resaltar el sticky */}
              <div className="border-b border-gray-200 mb-2" />
            </div>

            {/* Contenido que hace scroll */}
            <div className="px-8 pb-8 flex-1 overflow-y-auto">
              {/* Paso 1: Facturación */}
              {step === 1 && datosFacturacion && (
                <FacturacionStep
                  idOrden={idOrden}
                  initialData={datosFacturacion}
                />
              )}

              {/* Paso 2: Instrumentos (Certificado de Calibración) */}
              {step === 2 && (
                <InstrumentosStep
                  idOrden={idOrden}
                  instrumentos={instrumentos}
                />
              )}

              {/* Paso 3: Vista Previa del Certificado */}
              {step === 3 && (
                <CertificadoPreviewStep
                  idOrden={idOrden}
                />
              )}

              {/* Paso 4: Encuesta */}
              {step === 4 && (
                <EncuestaCalidadStep
                  idOrden={idOrden}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServiceOrderDetailTwo;