import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServicePage } from "../../components/Services/ServicePage";
import { Brands } from "../../components/Brand/Brands";

const ServicesPage = () => {
  return (
    <Layout  header={1} footer={1} breadcrumb={"Servicios"}  title={'Nuestros servicios'} >
      {/* services */}
      <ServicePage />
      <Brands />
      
    </Layout>
  );
};

export default ServicesPage;
