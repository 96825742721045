import { Layout } from "../../layouts/Layout";
import InfoPatterns from "../../components/Patterns/InfoPatterns";
import PatternsTable from "../../components/Patterns/PatternsTable";
import { Brands } from "../../components/Brand/Brands";

const PatternsPage = () => {
  return (
    <Layout header={1} footer={1} breadcrumb={"patrones"} title={"Patrones"}>
      
      <InfoPatterns />
      
      <PatternsTable />
      <Brands/>

    </Layout>
  );
};

export default PatternsPage;
