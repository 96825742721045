import React from "react";
import { Link } from "react-router-dom";
import { Odometer } from "../Odometer/Odometer";
import {
  INNER_ABOUT_IMG01,
  INNER_ABOUT_IMG02,
  INNER_ABOUT_SHAPE01,
} from "../../lib/assets";
import { GiTrophiesShelf } from "react-icons/gi";
import { AiFillSafetyCertificate } from "react-icons/ai";

export const AboutUs = () => {
  return (
    <section className="about-area-seven pt-120 pb-180">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9 order-0 order-lg-2">
            <div className="about-img-seven-wrap">
              <img
                src={INNER_ABOUT_IMG01}
                alt=""
                data-aos="fade-right"
                data-aos-delay="0"
              />
              <img
                src={INNER_ABOUT_IMG02}
                alt=""
                data-aos="fade-up"
                data-aos-delay="300"
              />
              <img
                src={INNER_ABOUT_SHAPE01}
                alt=""
                className="shape"
                data-aos="zoom-in"
                data-aos-delay="500"
              />
              <div
                className="experience-wrap"
                data-aos="fade-left"
                data-aos-delay="0"
              >
                <h2 className="title">32</h2>
                <p>Años de experiencia</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-seven">
              <div className="section-title mb-30">
                <span className="sub-title">Quiénes somos</span>
                <h2 className="title">
                 Innovación y excelencia en calibración desde 1992
                </h2>
              </div>
              <p>
              Más de tres décadas liderando el mercado de calibración con tecnología avanzada, 
              reconocimiento internacional y un enfoque en la mejora continua.
              </p>

              <div className="success-wrap-two">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                    <AiFillSafetyCertificate />
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={100} />%
                      </h2>
                      <p>ISO 17025</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                    <GiTrophiesShelf />
                    </div>
                    <div className="content">
                      <h2 className="count">+
                        <Odometer end={30} />
                      </h2>
                      <p>Reconocimientos</p>
                    </div>
                  </li>
                </ul>
              </div>

              <p className="info-two">
              Con un compromiso del 100% en cumplir con los estándares internacionales de ISO 17025, 
              CalTechnix ha obtenido más de 30 reconocimientos que avalan la precisión y calidad de sus servicios de calibración, 
              consolidándose como líder en el sector a nivel global.
              </p>

              <Link to="/contact" className="btn btn-three">
                Contactar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
