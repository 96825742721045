import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
  SD_WORKING_IMG02,
  SERVICES_DETAILS03,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const TemperaturaServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Servicio de temperatura"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          {/* Sección de imagen principal */}
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="Calibración de temperatura" />
          </div>

          {/* Contenido principal */}
          <div className="services-details-content">
            <h2 className="title">Calibración Especializada en Temperatura</h2>
            <p>
              La exactitud en la medición de la temperatura es esencial para
              diversas industrias, desde la fabricación de alimentos hasta la
              petroquímica. En CalTechnix, ofrecemos un servicio integral de
              calibración para termómetros, termopares, sensores RTD y otros
              instrumentos térmicos.
            </p>

            {/* Sección: ¿En qué consiste la calibración de temperatura? */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <h3>¿En qué consiste la calibración de temperatura?</h3>
              <p>
                Consiste en comparar las lecturas de sus instrumentos con
                patrones reconocidos, asegurando la validez de cada medición.
                Nuestra experiencia nos permite adaptar el proceso a las
                exigencias de distintos sectores, ya sea la supervisión de
                hornos, refrigeración industrial o procesos químicos sensibles.
              </p>
              <ul className="list-wrap">
                <li>
                  <img src={CHECK_ICON} alt="check icon" />
                  Alineación de termómetros con patrones internacionales
                </li>
                <li>
                  <img src={CHECK_ICON} alt="check icon" />
                  Garantía de reproducibilidad en distintas condiciones
                </li>
                <li>
                  <img src={CHECK_ICON} alt="check icon" />
                  Aseguramiento de la calidad y trazabilidad metrológica
                </li>
              </ul>
            </div>

            {/* Sección: Importancia en procesos industriales */}
            <div className="industrial-working-wrap">
              <h3>¿Por qué es vital la calibración térmica en sus procesos?</h3>
              <p className="info-one">
                Una medición inexacta de temperatura puede ocasionar defectos en
                la producción, sobrecostos de energía o riesgos en la salud de
                los consumidores. Mantener sus equipos al día con la
                calibración, no solo evita estos escenarios, sino que optimiza
                la eficiencia y conserva la confianza del mercado en sus
                productos.
              </p>

              {/* Ejemplos de imágenes */}
              <div className="row">
                <div className="col-md-6">
                  <img
                    src={SD_WORKING_IMG01}
                    alt="Personal de CalTechnix calibrando termómetros"
                  />
                </div>
                <div className="col-md-6">
                  <img
                    src={SD_WORKING_IMG02}
                    alt="Equipo de control de temperatura en laboratorio"
                  />
                </div>
              </div>
            </div>

            {/* Sección: Nuestra acreditación */}
            <div className="sd-inner-wrap sd-inner-wrap-two">
              <h3>Nuestra Acreditación en Temperatura</h3>
              <p>
                Bajo la norma ISO17025, estamos acreditados como T-154 ante la
                entidad mexicana de acreditación, lo que ratifica la solidez de
                nuestros procedimientos y la confiabilidad de nuestros resultados.
                Trabajamos desde laboratorios especializados y también podemos
                trasladarnos a sus instalaciones para minimizar los tiempos
                de inactividad.
              </p>
              <p>
                Al elegir CalTechnix, dispone de un respaldo técnico con amplia
                experiencia y la tranquilidad de saber que cada calibración
                está sometida a estrictos estándares internacionales.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default TemperaturaServicePage;
