import {
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  H4_SERVICES_IMG06,
  H5_SERVICES_IMG05,
  H6_SERVICES_IMG06,
} from "../lib/assets";

import { MdElectricBolt, MdOutlinePlayForWork } from "react-icons/md";
import { FaBalanceScale, FaCompressArrowsAlt, FaTemperatureHigh, FaTools, FaTint } from "react-icons/fa";

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: <FaCompressArrowsAlt />,
    href: "/services/presion",
    title: "Presión",
    subtitle: "Calibramos manómetros, transductores y más, en planta o laboratorio.",
    point_one: "Manómetros y transductores industriales",
    point_two: "Servicio especializado en planta y laboratorio",
    accreditation: {
      extraText: `Esta acreditación certifica nuestras calibraciones en presión 
      bajo normas ISO17025, asegurando mediciones trazables a patrones internacionales.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/P-36.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Presion/RatificacionPresion_10-24.pdf",
    },
  },
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: <MdOutlinePlayForWork />,
    href: "/services/fuerza",
    title: "Fuerza",
    subtitle: "Servicio para celdas de carga, dinamómetros y máquinas de ensayo.",
    point_one: "Calibración de celdas de carga y dinamómetros",
    point_two: "Pruebas en máquinas voluminosas en sitio",
    accreditation: {
      extraText: `Nuestra acreditación en fuerza abarca instrumentos y procedimientos 
      robustos que garantizan exactitud y confiabilidad bajo altos estándares.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/F-28.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Fuerza/RatificacionFuerza_10-24.pdf",
    },
  },
  // 3) Temperatura
  {
    id: 3,
    src: H4_SERVICES_IMG03,
    icon: <FaTemperatureHigh />,
    href: "/services/temperatura",
    title: "Temperatura",
    subtitle: "Calibración fiable de termómetros de alta precisión.",
    point_one: "Termómetros de uso industrial y laboratorio",
    point_two: "Control exacto en fundición, petroquímica y más",
    accreditation: {
      extraText: `Nuestro rango de calibración en temperatura cubre aplicaciones 
      en industria alimentaria, farmacéutica y petroquímica, asegurando fiabilidad.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/T-154.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Temperatura/RatificacionTemp_10-24.pdf",
    },
  },
  // 4) Eléctrica
  {
    id: 4,
    src: H4_SERVICES_IMG04,
    icon: <MdElectricBolt />,
    href: "/services/electrica",
    title: "Eléctrica",
    subtitle: "Calibración de voltímetros, amperímetros y similares.",
    point_one: "Voltímetros, amperímetros y de potencia",
    point_two: "Bajo norma ISO17025 con informes certificados",
    accreditation: {
      extraText: `Calibramos desde multímetros básicos hasta equipos de 
      alta precisión industrial, cumpliendo con la norma ISO17025.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/E-154.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Electrica/RatificacionElect10-24.pdf",
    },
  },
  // 5) Masa
  {
    id: 5,
    src: H5_SERVICES_IMG05,
    icon: <FaBalanceScale />,
    href: "/services/masa",
    title: "Masa",
    subtitle: "Calibramos instrumentos para pesar de funcionamiento no automático, pesas y más.",
    point_one: "Servicio de pesas y balanzas industriales",
    point_two: "Procedimientos confiables en laboratorio",
    accreditation: {
      extraText: `Podemos calibrar tanto masa como balanzas. La calibración de balanzas
      puede requerir transportar pesas patrón. Todo bajo la norma ISO17025.`,
      accreditationLink: "https://caltechnix.com/Acreditacion/M-201.pdf",
      ratificationLink: "https://caltechnix.com/Acreditacion/Masa/RatificacionMasa_10-24.pdf",
    },
  },
  // 6) Par Torsional
  {
    id: 6,
    src: H6_SERVICES_IMG06,
    icon: <FaTools />,
    href: "/services/par-torsional",
    title: "Par Torsional",
    subtitle: "Calibración de torquímetros, herramientas y transductores de torque.",
    point_one: "Torquímetros y medidores de par industriales",
    point_two: "Herramientas de apriete con exactitud garantizada",
    accreditation: {
      extraText: `También calibramos instrumentos voluminosos en las instalaciones del cliente,
      previa evaluación de factibilidad, bajo la norma ISO17025.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/PT-04.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/ParTorsional/RatificacionPar_10-24.pdf",
    },
  },
  // 7) Humedad
  {
    id: 7,
    src: H4_SERVICES_IMG06, // Ajusta a la imagen correspondiente si dispones de ella
    icon: <FaTint />,
    href: "/services/humedad",
    title: "Humedad",
    subtitle: "Calibración confiable de higrómetros, sondas y sistemas de monitoreo.",
    point_one: "Calibración de higrómetros, termohigrómetros y transmisores",
    point_two: "Aplicaciones en industria alimentaria, farmacéutica y más",
    accreditation: {
      extraText: `Garantizamos trazabilidad y exactitud en la medición de humedad
      bajo la norma ISO17025, cubriendo una amplia gama de aplicaciones.`,
      accreditationLink: "https://caltechnix.com/Acreditacion/H-54.pdf", // Ajusta el link real
      ratificationLink: "https://caltechnix.com/Acreditacion/Humedad/RatificacionHumedad_10-24.pdf", // Ajusta el link real
    },
  },
];

export const SERVICES_PAGE_THREE_LIST = [
  // 1) Presión
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: <FaCompressArrowsAlt />,
    href: "/services/presion",
    title: "Presión",
    subtitle: "Calibramos manómetros, transductores y más, en planta o laboratorio.",
    point_one: "Manómetros y transductores industriales",
    point_two: "Servicio especializado en planta y laboratorio",
    accreditation: {
      extraText: `Esta acreditación certifica nuestras calibraciones en presión 
      bajo normas ISO17025, asegurando mediciones trazables a patrones internacionales.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/P-36.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Presion/RatificacionPresion_10-24.pdf",
    },
  },
  // 2) Fuerza
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: <MdOutlinePlayForWork />,
    href: "/services/fuerza",
    title: "Fuerza",
    subtitle: "Servicio para celdas de carga, dinamómetros y máquinas de ensayo.",
    point_one: "Calibración de celdas de carga y dinamómetros",
    point_two: "Pruebas en máquinas voluminosas en sitio",
    accreditation: {
      extraText: `Nuestra acreditación en fuerza abarca instrumentos y procedimientos 
      robustos que garantizan exactitud y confiabilidad bajo altos estándares.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/F-28.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Fuerza/RatificacionFuerza_10-24.pdf",
    },
  },
  // 3) Temperatura
  {
    id: 3,
    src: H4_SERVICES_IMG03,
    icon: <FaTemperatureHigh />,
    href: "/services/temperatura",
    title: "Temperatura",
    subtitle: "Calibración fiable de termómetros de alta precisión.",
    point_one: "Termómetros de uso industrial y laboratorio",
    point_two: "Control exacto en fundición, petroquímica y más",
    accreditation: {
      extraText: `Nuestro rango de calibración en temperatura cubre aplicaciones 
      en industria alimentaria, farmacéutica y petroquímica, asegurando fiabilidad.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/T-154.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Temperatura/RatificacionTemp_10-24.pdf",
    },
  },
  // 4) Eléctrica
  {
    id: 4,
    src: H4_SERVICES_IMG04,
    icon: <MdElectricBolt />,
    href: "/services/electrica",
    title: "Eléctrica",
    subtitle: "Calibración de voltímetros, amperímetros y similares.",
    point_one: "Voltímetros, amperímetros y de potencia",
    point_two: "Bajo norma ISO17025 con informes certificados",
    accreditation: {
      extraText: `Calibramos desde multímetros básicos hasta equipos de 
      alta precisión industrial, cumpliendo con la norma ISO17025.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/E-154.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/Electrica/RatificacionElect10-24.pdf",
    },
  },
  // 5) Masa
  {
    id: 5,
    src: H5_SERVICES_IMG05,
    icon: <FaBalanceScale />,
    href: "/services/masa",
    title: "Masa",
    subtitle: "Calibramos instrumentos para pesar de funcionamiento no automático, pesas y más.",
    point_one: "Servicio de pesas y balanzas industriales",
    point_two: "Procedimientos confiables en laboratorio",
    accreditation: {
      extraText: `Podemos calibrar tanto masa como balanzas. La calibración de balanzas
      puede requerir transportar pesas patrón. Todo bajo la norma ISO17025.`,
      accreditationLink: "https://caltechnix.com/Acreditacion/M-201.pdf",
      ratificationLink: "https://caltechnix.com/Acreditacion/Masa/RatificacionMasa_10-24.pdf",
    },
  },
  // 6) Par Torsional
  {
    id: 6,
    src: H6_SERVICES_IMG06,
    icon: <FaTools />,
    href: "/services/par-torsional",
    title: "Par Torsional",
    subtitle: "Calibración de torquímetros, herramientas y transductores de torque.",
    point_one: "Torquímetros y medidores de par industriales",
    point_two: "Herramientas de apriete con exactitud garantizada",
    accreditation: {
      extraText: `También calibramos instrumentos voluminosos en las instalaciones del cliente,
      previa evaluación de factibilidad, bajo la norma ISO17025.`,
      accreditationLink: "https://www.caltechnix.com/Acreditacion/PT-04.pdf",
      ratificationLink: "https://www.caltechnix.com/Acreditacion/ParTorsional/RatificacionPar_10-24.pdf",
    },
  },
  // 7) Humedad
  {
    id: 7,
    src: H4_SERVICES_IMG06, // Ajusta a la imagen correspondiente si dispones de ella
    icon: <FaTint />,
    href: "/services/humedad",
    title: "Humedad",
    subtitle: "Calibración confiable de higrómetros, sondas y sistemas de monitoreo.",
    point_one: "Calibración de higrómetros, termohigrómetros y transmisores",
    point_two: "Aplicaciones en industria alimentaria, farmacéutica y más",
    accreditation: {
      extraText: `Garantizamos trazabilidad y exactitud en la medición de humedad
      bajo la norma ISO17025, cubriendo una amplia gama de aplicaciones.`,
      accreditationLink: "https://caltechnix.com/Acreditacion/H-54.pdf", // Ajusta el link real
      ratificationLink: "https://caltechnix.com/Acreditacion/Humedad/RatificacionHumedad_10-24.pdf", // Ajusta el link real
    },
  },
];
