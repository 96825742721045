import React from "react";
import { Layout } from "../../layouts/Layout";
// Reemplaza las constantes con las rutas de tus imágenes o íconos relevantes
import {
  CHECK_ICON,
  SERVICES_DETAILS03, // Puedes sustituir con alguna imagen relacionada con torque
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const ParTorsionalServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Calibración de par torsional"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          {/* Imagen principal (opcional) */}
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="Calibración de par torsional" />
          </div>

          {/* Contenido principal */}
          <div className="services-details-content">
            {/* TÍTULO PRINCIPAL */}
            <h2 className="title">¿Quién no habrá visto un tornillo o una tuerca?</h2>

            {/* DESCRIPCIÓN PRINCIPAL */}
            <p>
              Estos elementos de unión se utilizan en los más diversos procesos y, 
              para asegurar una unión confiable, se usan torquímetros. Calibramos, 
              de forma ilustrativa, torquímetros de carátula, tipo click, tipo 
              desarmador, calibradores de torquímetros, multiplicadores de torque, 
              herramientas de apriete, bancos de prueba para motores, torquímetros 
              para tapas roscadas de frascos y botellas, etc.
            </p>
            <p>
              También, en el área de par torsional, existen instrumentos que 
              necesariamente debemos calibrar en las instalaciones del cliente. 
              Por la gran diversidad de equipos, realizamos un estudio de factibilidad 
              para determinar si la calibración en planta es una opción viable 
              frente a realizarla en nuestras instalaciones.
            </p>

            {/* BLOQUE INTERNO */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                {/* COLUMNA IZQUIERDA */}
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de Torquímetros</h5>
                          <p>
                            Verificamos y ajustamos torquímetros de distintas 
                            configuraciones (carátula, click, desarmador, etc.) 
                            para garantizar mediciones confiables y evitar fallas 
                            en los procesos de sujeción.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Multiplicadores y Herramientas Especiales</h5>
                          <p>
                            Contamos con procedimientos de calibración para 
                            multiplicadores de torque y herramientas de apriete 
                            empleados en la industria automotriz, manufacturera 
                            y otras áreas que requieren un control estricto del 
                            par torsional.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* COLUMNA DERECHA */}
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">¿Por qué elegir CalTechnix?</h3>
                    <p>
                      En CalTechnix, comprendemos la importancia de un control 
                      preciso del torque en diferentes industrias. Nuestro equipo 
                      de expertos ofrece:
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Amplia experiencia en calibración de torquímetros
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Procesos y laboratorios acreditados bajo normas vigentes
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Opción de calibración en planta para equipos difíciles de trasladar
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Resultados trazables y confiables
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* SECCIÓN DE EXPERIENCIA Y AVAL */}
            <div className="industrial-working-wrap">
              <h2 className="title-two">Experiencia y Aval en Calibración de Par Torsional</h2>
              <p className="info-one">
                Por la gran diversidad de instrumentos en el área de par torsional, 
                ofrecemos un estudio de factibilidad de calibración in situ, 
                brindando soluciones prácticas para aquellos equipos que, por su 
                tamaño o características, requieren calibraciones en las instalaciones 
                del cliente.
              </p>
              <p className="info-two">
                Consulte más acerca de nuestra acreditación y limitaciones de exactitud 
                en la sección de{" "}
                <a href="/acreditaciones" target="_blank">
                  Acreditaciones
                </a>
                . En CalTechnix, encontrará un aliado confiable para asegurar 
                que todos sus procesos de apriete cumplan con los estándares 
                de calidad y seguridad que su industria demanda.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default ParTorsionalServicePage;
