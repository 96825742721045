import { AboutHome } from "../../components/About/AboutHome";
import { Brands } from "../../components/Brand/Brands";
import { ChooseAreaHome } from "../../components/ChooseAreas/ChooseAreaHome";
import { ServicesHome } from "../../components/Services/ServicesHome";
import { ContactAreaInner } from "../../components/ContactAreas/ContactAreaInner";
import { Layout } from "../../layouts/Layout";

const HomeThree = () => {
  return (
    <Layout header={1} footer={1}>
      
      <ServicesHome />
      
      <AboutHome />

      <ChooseAreaHome />
     
      <ContactAreaInner />

      <Brands />

    </Layout>
  );
};

export default HomeThree;
