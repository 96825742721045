import React from "react";
import { Layout } from "../../layouts/Layout";
// Puedes reemplazar estas imágenes y/o íconos si así lo deseas
import {
  CHECK_ICON,
  SERVICES_DETAILS03, // Imagen principal de la sección (puede ser cambiada)
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const MasaServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Calibración de masa"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          {/* Imagen principal (opcional) */}
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="Calibración de masa" />
          </div>

          {/* Contenido principal */}
          <div className="services-details-content">
            {/* TÍTULO PRINCIPAL */}
            <h2 className="title">¿Son lo mismo la masa y el peso?</h2>

            {/* DESCRIPCIÓN PRINCIPAL */}
            <p>
              La masa es la cantidad de materia de un cuerpo que se mide con una
              balanza y se expresa en la unidad de kilogramo (kg). El peso es la
              cuantificación de la fuerza de atracción gravitacional ejercida
              sobre un cuerpo de determinada masa. Por lo tanto, la masa de un
              objeto no cambiará de valor sea cual sea la ubicación que tenga
              sobre la superficie de la Tierra.
            </p>
            <p>
              Desde un punto de vista técnico, cada vez que alguien se para sobre
              una báscula de contrapesos (o balanza romana) en el consultorio de
              un médico, lo que en realidad se mide es su masa. Esto es porque
              en este tipo de balanzas (comparadores de masa de “doble platillo”)
              se compara el peso de la masa en la plataforma con el de unos
              contrapesos que se deslizan sobre el brazo con marcas; la gravedad
              es solo el mecanismo que genera la fuerza que permite que la aguja
              se desplace con respecto a la posición de equilibrio o “balance”
              (cero). Este tipo de balanzas pueden ser desplazadas desde el
              ecuador a los polos y no indicarán variaciones en sus lecturas; son
              inmunes a la fuerza centrífuga que genera la Tierra al rotar sobre
              su eje que contrarresta la gravedad.
            </p>
            <p>
              Por otra parte, cada vez que alguien se para sobre balanzas que
              utilizan resortes o celdas de carga digitales, técnicamente lo que
              miden es su peso (fuerza causada por la fuerza de gravedad). En el
              caso de instrumentos que miden fuerza, como los descritos con
              anterioridad, las variaciones en la intensidad de la gravedad
              afectan su medición. Desde un punto de vista práctico, cuando se
              usan balanzas que miden fuerzas en el comercio o en hospitales, las
              mismas deben ser calibradas y certificadas en el sitio en que se
              utilizan de forma tal que midan masa, expresada en kilogramos o
              libras, con el nivel de precisión deseado.
            </p>

            {/* BLOQUE INTERNO */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                {/* COLUMNA IZQUIERDA */}
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de masa</h5>
                          <p>
                            En el área de masa podemos calibrar tanto la masa
                            misma (pesas patrón) como distintos tipos de
                            balanzas. De esta manera se asegura que las
                            mediciones de masa sean precisas y confiables.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de balanzas</h5>
                          <p>
                            Calibramos balanzas de contrapesos y balanzas con
                            resortes o celdas de carga digitales. En algunos
                            casos, la calibración se realiza en el mismo sitio de
                            uso debido a la influencia de la gravedad local.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* COLUMNA DERECHA */}
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">¿Por qué elegir CalTechnix?</h3>
                    <p>
                      En CalTechnix estamos comprometidos con la exactitud y
                      confiabilidad de sus mediciones de masa. Somos conscientes
                      de las variaciones gravitatorias y de la necesidad de usar
                      pesas patrón calibradas bajo estándares internacionales.
                      Nuestro enfoque integral permite:
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Personal experto en metrología de masa
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Calibraciones trazables bajo normas vigentes
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Ajuste y certificación in situ para balanzas sensibles
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Acreditaciones que avalan la calidad del servicio
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* SECCIÓN DE EXPERIENCIA Y AVAL */}
            <div className="industrial-working-wrap">
              <h2 className="title-two">Experiencia y Aval en Calibración de Masa</h2>
              <p className="info-one">
                Nuestra capacidad de calibración en masa nos permite ofrecer
                resultados confiables y precisos. En el área de masa podemos
                calibrar tanto masa como balanzas. La calibración de balanzas
                está limitada a alcances pequeños debido a la necesidad de
                transportar pesas patrón, pero nos adaptamos a las exigencias de
                cada cliente para garantizar la exactitud requerida.
              </p>
              <p className="info-two">
                Consulte más acerca de nuestra acreditación y limitaciones de
                exactitud en la sección de{" "}
                <a href="/acreditaciones" target="_blank">
                  Acreditaciones
                </a>
                . Con CalTechnix, usted cuenta con el respaldo de un equipo
                experto y procedimientos auditados, asegurando que sus
                mediciones de masa cumplan con los más altos estándares de
                calidad.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default MasaServicePage;
