import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const BACKEND_URL = "https://caltechnix-backend-gamma.vercel.app";

const CertificadoPreviewStep = ({
  idOrden,
  ordenServicio,
  instrumentos,
  readOnly = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [factura, setFactura] = useState({
    OrdenServicio: "",
    Empresa: "",
    Direccion: "",
    Ciudad: "",
    Municipio: "",
    Estado: "",
    CP: "",
  });

  const [localInstruments, setLocalInstruments] = useState([]);

  useEffect(() => {
    if (!idOrden) return;

    const fetchCertData = async () => {
      setLoading(true);
      setErrorMsg("");
      try {
        const resp = await fetch(`${BACKEND_URL}/api/datos-certificado`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ idOrden }),
        });

        if (resp.status === 403) {
          setErrorMsg("La fecha límite ha expirado o la orden ya está confirmada.");
          setLoading(false);
          return;
        }

        const data = await resp.json();
        if (!resp.ok || data.error) {
          setErrorMsg(data.error || "Error al obtener datos del Certificado");
        } else {
          setFactura({
            OrdenServicio: data.OrdenServicio || "",
            Empresa: data.Empresa || "",
            Direccion: data.Direccion || "",
            Ciudad: data.Ciudad || "",
            Municipio: data.Municipio || "",
            Estado: data.Estado || "",
            CP: data.CP || "",
          });

          let combined = [];
          if (data.instrumentos) {
            combined = [...data.instrumentos];
          }
          if (data.instrumentosAdicional) {
            combined = [...combined, ...data.instrumentosAdicional];
          }
          setLocalInstruments(combined);
        }
      } catch (error) {
        console.error(error);
        setErrorMsg("Error de conexión al cargar Certificado");
      } finally {
        setLoading(false);
      }
    };

    fetchCertData();
  }, [idOrden]);

  if (loading && !errorMsg) {
    return <p>Cargando...</p>;
  }
  if (errorMsg) {
    return <p className="text-red-500">{errorMsg}</p>;
  }

  if (!factura.OrdenServicio && !localInstruments.length && !loading) {
    return null;
  }

  return (
    <div className="relative flex flex-col h-full">
      <div className="sticky top-0 z-10 bg-white">
        <div className="px-8 pt-8 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-700 mb-2">
              ID de Orden:{" "}
              <span className="text-blue-600">{ordenServicio}</span>
            </h1>
            <p className="text-sm text-gray-500 mb-4">
              Previsualiza la información que aparecerá en tu certificado.
            </p>
            <div className="border-b border-gray-200 mb-2" />
          </div>
        </div>
      </div>

      <div className="px-8 pb-24 flex-1 overflow-y-auto">
        <div
          className="border-2 p-4 rounded-xl shadow-md"
          style={{ backgroundColor: "#e2ebf0", borderColor: "#3b82f6" }}
        >
          <h1
            className="text-2xl font-bold text-center mb-2"
            style={{ color: "#0a3f66", fontFamily: "Georgia, serif" }}
          >
            Certificado de Calibración
          </h1>

          <div
            className="shadow-inner p-3 rounded-lg"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="mb-2">
              <label className="text-sm font-semibold block mb-1">
                No. de Certificado:
              </label>
              <input
                name="OrdenServicio"
                value={factura.OrdenServicio}
                readOnly
                className="w-full border border-gray-300 rounded p-1 text-sm bg-gray-100 text-gray-600"
              />
            </div>

            <div className="mb-2">
              <label className="text-sm font-semibold block mb-1">
                Cliente / Empresa:
              </label>
              <input
                name="Empresa"
                value={factura.Empresa}
                readOnly
                className="w-full border border-gray-300 rounded p-1 text-sm bg-gray-100 text-gray-600"
              />
            </div>

            <div className="mb-2">
              <label className="text-sm font-semibold block mb-1">
                Dirección:
              </label>
              <input
                name="Direccion"
                value={factura.Direccion}
                readOnly
                className="w-full border border-gray-300 rounded p-1 text-sm bg-gray-100 text-gray-600"
              />
            </div>

            <div className="mb-2">
              <label className="text-sm font-semibold block mb-1">
                Mpio/Alc - Ciudad - Estado - C.P.:
              </label>
              <div className="flex gap-2">
                <input
                  name="Municipio"
                  value={factura.Municipio}
                  readOnly
                  className="border border-gray-300 rounded p-1 text-sm flex-1 bg-gray-100 text-gray-600"
                />
                <input
                  name="Ciudad"
                  value={factura.Ciudad}
                  readOnly
                  className="border border-gray-300 rounded p-1 text-sm flex-1 bg-gray-100 text-gray-600"
                />
                <input
                  name="Estado"
                  value={factura.Estado}
                  readOnly
                  className="border border-gray-300 rounded p-1 text-sm flex-1 bg-gray-100 text-gray-600"
                />
                <input
                  name="CP"
                  value={factura.CP}
                  readOnly
                  className="border border-gray-300 rounded p-1 text-sm w-20 bg-gray-100 text-gray-600"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Instrumentos (solo lectura) */}
        <div className="mt-4 space-y-4">
          {localInstruments.map((inst, idx) => (
            <div
              key={inst.idDatoCert ? `${inst.idDatoCert}-${idx}` : idx}
              className="border border-gray-300 rounded-xl p-4 shadow"
              style={{ backgroundColor: "#f3f4f6" }}
            >
              <div className="flex items-center justify-between border-b border-gray-300 pb-2 mb-2">
                <div className="text-sm font-semibold text-gray-700">
                  <label className="mr-2">Instrumento {idx + 1}:</label>
                  <input
                    value={inst.Instrumento || ""}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-xs bg-gray-100 text-gray-600"
                  />
                </div>
                <span className="text-xs text-gray-500">
                  Instr. {idx + 1} / {localInstruments.length}
                </span>
              </div>

              <div className="grid grid-cols-2 gap-2 text-sm">
                <div>
                  <label className="font-semibold block mb-1">Marca:</label>
                  <input
                    value={inst.Marca || ""}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-sm w-full bg-gray-100 text-gray-600"
                  />
                </div>
                <div>
                  <label className="font-semibold block mb-1">Modelo:</label>
                  <input
                    value={inst.Modelo || ""}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-sm w-full bg-gray-100 text-gray-600"
                  />
                </div>

                <div>
                  <label className="font-semibold block mb-1">
                    No. Serie:
                  </label>
                  <input
                    value={inst.Serie || ""}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-sm w-full bg-gray-100 text-gray-600"
                  />
                </div>
                <div>
                  <label className="font-semibold block mb-1">
                    Ident. Cliente:
                  </label>
                  <input
                    value={inst.Identificacion || ""}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-sm w-full bg-gray-100 text-gray-600"
                  />
                </div>
              </div>

              <div className="mt-3 grid grid-cols-2 gap-2 text-sm">
                <div>
                  <label className="font-semibold block mb-1">
                    Intervalo de medida:
                  </label>
                  <input
                    value={`${inst.IntervaloInicio || ""} - ${
                      inst.IntervaloFinal || ""
                    } ${inst.Unidad || ""}`}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-sm w-full bg-gray-100 text-gray-600"
                  />
                </div>
                <div>
                  <label className="font-semibold block mb-1">
                    Requisitos específicos:
                  </label>
                  <input
                    value={inst.PuntosEsp || ""}
                    readOnly
                    className="border border-gray-200 rounded p-1 text-sm w-full bg-gray-100 text-gray-600"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CertificadoPreviewStep;
