import React, { useState } from "react";
import { Link } from "react-router-dom";
/** Importamos createPortal para inyectar el banner en <body> */
import { createPortal } from "react-dom";

export const ServiceHomeItem = ({ service }) => {
  // Estado para mostrar/ocultar el "modal" tipo banner
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="services-item">
      <div className="services-content">
        <div className="content-top">
          <div className="icon">{service.icon}</div>
          <h2 className="title">{service.title}</h2>
        </div>

        <div className="services-thumb">
          <img
            className="w-full h-full object-cover"
            src={service.src}
            alt={service.title}
          />
          {/* Al hacer clic, abrimos nuestro banner (modal) */}
          <Link to="#" className="btn transparent-btn" onClick={handleOpenModal}>
            Ver acreditación
          </Link>
        </div>

        <ul className="list-wrap">
          <li>{service.point_one}</li>
          <li>{service.point_two}</li>
        </ul>
      </div>

      {/* Aquí inyectamos el modal en el body con createPortal */}
      {openModal &&
        createPortal(
          <div className="banner-backdrop">
            <div className="banner-content">
              {/* Título principal */}
              <h1 className="text-2xl font-bold text-blue-500 mb-4 uppercase">
                {service.title}
              </h1>

          
              {/* Texto adicional del servicio (si aplica) */}
              {service?.accreditation?.extraText && (
                <p className="text-gray-700 mb-4 text-lg text-center">
                  {service.accreditation.extraText}
                </p>
              )}

              {/* Botones de Acreditación y Ratificación */}
              <div className="flex flex-col sm:flex-row gap-3 justify-center items-center mb-4">
                {service?.accreditation?.accreditationLink && (
                  <a
                    href={service.accreditation.accreditationLink}
                    style={{ borderRadius: "1rem" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-5 py-3 bg-blue-600 hover:bg-blue-800 text-white font-medium rounded-md shadow transition-all"
                  >
                    Ver Acreditación
                  </a>
                )}
                {service?.accreditation?.ratificationLink && (
                  <a
                  style={{ borderRadius: "1rem" }}
                    href={service.accreditation.ratificationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-5 py-3 bg-green-600 hover:bg-green-800 text-white font-medium rounded-md shadow transition-all"
                  >
                    Ver Ratificación
                  </a>
                )}
              </div>

              {/* Botón para cerrar (o “He leído y acepto iniciar el proceso”) */}
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded w-2/6"
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
            </div>
          </div>,
          document.body // <-- ¡Se inyecta directamente al body!
        )}
    </div>
  );
};
