// assets.js

// Logos
import logo from "../assets/img/logo/logo.png";
import w_logo from "../assets/img/logo/w_logo.png"; 

// Banner
import h4_banner_img from "../assets/img/banner/h4_banner_img.png";

// About
import about_author from "../assets/img/images/about_author.png";
import h4_about_img01 from "../assets/img/images/h4_about_img01.jpg";
import h4_about_img02 from "../assets/img/images/h4_about_img02.jpg";

// Choose section
import choose_shape from "../assets/img/images/choose_shape.png";
import h4_choose_img from "../assets/img/images/h4_choose_img.png";
import choose_img_shape01 from "../assets/img/images/choose_img_shape01.png";
import choose_img_shape02 from "../assets/img/images/choose_img_shape02.png";

// Brand
import brand_img01 from "../assets/img/brand/brand_img01.png";
import brand_img02 from "../assets/img/brand/brand_img02.png";
import brand_img03 from "../assets/img/brand/brand_img03.png";
import brand_img04 from "../assets/img/brand/brand_img04.png";
import brand_img05 from "../assets/img/brand/brand_img05.png";

// Services
import h4_services_img01 from "../assets/img/services/h4_services_img01.jpg";
import h4_services_img02 from "../assets/img/services/h4_services_img02.jpg";
import h4_services_img03 from "../assets/img/services/h4_services_img03.jpg";
import h4_services_img04 from "../assets/img/services/h4_services_img04.jpg";
import h5_services_img05 from "../assets/img/services/h5_services_img05.jpg";
import h6_services_img06 from "../assets/img/services/h6_services_img06.jpg";
import h4_services_img06 from "../assets/img/services/h4_services_img06.jpg";
import h4_services_shape from "../assets/img/services/h4_services_shape.png";

// Icons
import check_icon from "../assets/img/icons/check_icon.svg";
import check_icon02 from "../assets/img/icons/check_icon02.svg";
import check_icon03 from "../assets/img/icons/check_icon03.svg";
import right_arrow from "../assets/img/icons/right-arrow.svg";
import quote02 from "../assets/img/icons/quote02.svg";
import quote from "../assets/img/icons/quote.svg";
import plus_icon from "../assets/img/icons/plus_icon.svg";
import rating from "../assets/img/icons/rating.svg";

// Blog
import h2_blog_img01 from "../assets/img/blog/h2_blog_img01.jpg";
import h2_blog_img02 from "../assets/img/blog/h2_blog_img02.jpg";
import h2_blog_img03 from "../assets/img/blog/h2_blog_img03.jpg";
import h3_blog_img01 from "../assets/img/blog/h3_blog_img01.jpg";
import h4_blog_img01 from "../assets/img/blog/h4_blog_img01.jpg";
import h4_blog_img02 from "../assets/img/blog/h4_blog_img02.jpg";
import h4_blog_img03 from "../assets/img/blog/h4_blog_img03.jpg";
import blog_img01 from "../assets/img/blog/blog_img01.jpg";

// Breadcrumb (imágenes de fondo o forma)
import breadcrumb_shape01 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape02 from "../assets/img/images/breadcrumb_shape02.png";

// Inner About
import inner_about_img01 from "../assets/img/images/inner_about_img01.jpg";
import inner_about_img02 from "../assets/img/images/inner_about_img02.jpg";
import inner_about_img03 from "../assets/img/images/inner_about_img03.jpg";
import inner_about_shape01 from "../assets/img/images/inner_about_shape01.png";

// Inner Choose
import inner_choose_img from "../assets/img/images/inner_choose_img.jpg";

// Services Details
import services_details03 from "../assets/img/services/services_details03.jpg";

// SD Working
import sd_working_img01 from "../assets/img/services/sd_working_img01.jpg";
import sd_working_img02 from "../assets/img/services/sd_working_img02.jpg";

// Project Details
import project_details01 from "../assets/img/project/project_details01.jpg";
import project_details02 from "../assets/img/project/project_details02.jpg";

// Otros íconos / imágenes
import share from "../assets/img/icons/share.svg";
import trazabilidad_img from "../assets/img/trazabilidad/trazabilidad_img.jpeg";

//============================================================
// EXPORTACIONES
// Logos
export const LOGO = logo;
export const W_LOGO = w_logo;

// Banner
export const H4_BANNER_IMG = h4_banner_img;

// About
export const ABOUT_AUTHOR = about_author;
export const H4_ABOUT_IMG01 = h4_about_img01;
export const H4_ABOUT_IMG02 = h4_about_img02;

// Choose Section
export const CHOOSE_SHAPE = choose_shape;
export const H4_CHOOSE_IMG = h4_choose_img;
export const CHOOSE_IMG_SHAPE01 = choose_img_shape01;
export const CHOOSE_IMG_SHAPE02 = choose_img_shape02;

// Brand
export const BRAND_IMG01 = brand_img01;
export const BRAND_IMG02 = brand_img02;
export const BRAND_IMG03 = brand_img03;
export const BRAND_IMG04 = brand_img04;
export const BRAND_IMG05 = brand_img05;

// Services
export const H4_SERVICES_IMG01 = h4_services_img01;
export const H4_SERVICES_IMG02 = h4_services_img02;
export const H4_SERVICES_IMG03 = h4_services_img03;
export const H4_SERVICES_IMG04 = h4_services_img04;
export const H5_SERVICES_IMG05 = h5_services_img05;
export const H6_SERVICES_IMG06 = h6_services_img06;
export const H4_SERVICES_IMG06 = h4_services_img06;
export const H4_SERVICES_SHAPE = h4_services_shape;

// Icons
export const CHECK_ICON = check_icon;
export const CHECK_ICON02 = check_icon02;
export const CHECK_ICON03 = check_icon03;
export const RIGHT_ARROW = right_arrow;
export const QUOTE02 = quote02;
export const QUOTE = quote;
export const PLUS_ICON = plus_icon;
export const RATING = rating;

// Blog
export const H2_BLOG_IMG01 = h2_blog_img01;
export const H2_BLOG_IMG02 = h2_blog_img02;
export const H2_BLOG_IMG03 = h2_blog_img03;
export const H3_BLOG_IMG01 = h3_blog_img01;
export const H4_BLOG_IMG01 = h4_blog_img01;
export const H4_BLOG_IMG02 = h4_blog_img02;
export const H4_BLOG_IMG03 = h4_blog_img03;
export const BLOG_IMG01 = blog_img01;

// Breadcrumb
export const BREADCRUMB_SHAPE01 = breadcrumb_shape01;
export const BREADCRUMB_SHAPE02 = breadcrumb_shape02;

// Inner About
export const INNER_ABOUT_IMG01 = inner_about_img01;
export const INNER_ABOUT_IMG02 = inner_about_img02;
export const INNER_ABOUT_IMG03 = inner_about_img03;
export const INNER_ABOUT_SHAPE01 = inner_about_shape01;

// Inner Choose
export const INNER_CHOOSE_IMG = inner_choose_img;

// Services Details
export const SERVICES_DETAILS03 = services_details03;

// SD Working
export const SD_WORKING_IMG01 = sd_working_img01;
export const SD_WORKING_IMG02 = sd_working_img02;

// Project Details
export const PROJECT_DETAILS01 = project_details01;
export const PROJECT_DETAILS02 = project_details02;

// Otros
export const SHARE = share;
export const TRAZABILIDAD_IMG = trazabilidad_img;
