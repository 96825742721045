import React from "react";
import { Layout } from "../../layouts/Layout";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Layout header={1} footer={1} title={"Error 404"}>
      {/* error-area */}
      <section className="error-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="error-content">
                <h1 className="error-404">
                  4<span>0</span>4
                </h1>

                <h2 className="title">Página en construcción</h2>

                <p>
                  La página que buscas está actualmente en desarrollo y pronto
                  estará disponible. Mientras tanto, puedes volver al inicio
                  para seguir navegando.
                </p>

                <Link to="/" className="btn btn-three">
                  Ir a la Página Principal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ErrorPage;
