import React from "react";
import { Link } from "react-router-dom";

export const NavMenus = () => {
  return (
    <div className="navbar-wrap main-menu mt-3 d-none d-lg-flex">
      <ul className="navigation">
        <li>
          <Link to="/">Inicio</Link>
        </li>
        <li>
          <Link to="/about">Empresa</Link>
        </li>
        <li>
          <Link to="/services">Servicios</Link>
        </li>
        <li>
          <Link to="/patterns">Patrones</Link>
        </li>
        <li>
          <Link to="/traceability">Trazabilidad</Link>
        </li>
        <li>
          <Link to="https://encuesta.caltechnix.com.mx/Encuesta017.htm" target="_blank" >Encuesta</Link>
        </li>
      </ul>
    </div>
  );
};
