import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const BACKEND_URL = "https://caltechnix-backend-gamma.vercel.app";

const InstrumentosStep = ({
  idOrden,
  ordenServicio,
  instrumentos,
  onUpdateInstruments,
  readOnly = false,
}) => {
  const [allInstruments, setAllInstruments] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setAllInstruments(instrumentos);
  }, [instrumentos]);

  // Guarda un solo instrumento
  const saveSingleInstrument = async (instrument) => {
    if (readOnly) return;

    setIsSaving(true);
    try {
      const response = await fetch(
        `${BACKEND_URL}/api/datos-inst-certificado/update-single`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            idOrden,
            idDatoCert: instrument.idDatoCert,
            Instrumento: instrument.Instrumento,
            Marca: instrument.Marca,
            Modelo: instrument.Modelo,
            Serie: instrument.Serie,
            Identificacion: instrument.Identificacion,
            // IntervaloInicio, IntervaloFinal, PuntosEsp => Solo lectura
            // Others if needed
          }),
        }
      );

      if (response.status === 403) {
        const data403 = await response.json();
        Swal.fire({
          icon: "error",
          title: "No se puede actualizar instrumento",
          text: data403.error,
          confirmButtonColor: "#1d74b8",
        });
        return;
      }

      const data = await response.json();
      if (!response.ok || data.error) {
        Swal.fire({
          icon: "error",
          title: "Error al actualizar instrumento",
          text: data.error || "Ocurrió un problema.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setAllInstruments((prev) =>
          prev.map((inst) =>
            inst.idDatoCert === instrument.idDatoCert ? instrument : inst
          )
        );
        if (onUpdateInstruments) {
          onUpdateInstruments(
            allInstruments.map((inst) =>
              inst.idDatoCert === instrument.idDatoCert ? instrument : inst
            )
          );
        }

        Swal.fire({
          icon: "success",
          title: "Instrumento actualizado",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error de conexión",
        text: "No se pudo conectar con el servidor.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (index, field, value) => {
    if (readOnly) return;
    setAllInstruments((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  const handleBlur = (index) => {
    if (readOnly) return;
    const instrument = allInstruments[index];
    saveSingleInstrument(instrument);
  };

  const handleSaveAll = async () => {
    if (readOnly) return;
    for (let i = 0; i < allInstruments.length; i++) {
      await saveSingleInstrument(allInstruments[i]);
    }
  };

  return (
    <div className="relative flex flex-col h-full">
      <div className="sticky top-0 z-10 bg-white">
        <div className="px-8 pt-8 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-700 mb-2">
              ID de Orden:{" "}
              <span className="text-blue-600">{ordenServicio}</span>
            </h1>
            <p className="text-sm text-gray-500 mb-4">
              Edita la información de los instrumentos principales asociados a esta orden.
            </p>
            <div className="border-b border-gray-200 mb-2" />
          </div>

          {!readOnly && (
            <button
              onClick={handleSaveAll}
              disabled={isSaving}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-xl text-sm flex items-center justify-center"
              style={{ minWidth: "100px", borderRadius: "1rem" }}
            >
              {isSaving ? "Guardando..." : "Guardar cambios"}
            </button>
          )}
        </div>
      </div>

      <div className="px-8 pb-24 flex-1 overflow-y-auto">
        <h2 className="text-lg font-semibold text-gray-700 mt-4">
          Instrumentos de Calibración
        </h2>
        <p className="text-xs text-gray-500">
          Modifica la información que corresponda. El "Intervalo de medida" y los
          "Requisitos del cliente" son solo de lectura.
        </p>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {allInstruments.map((inst, index) => (
            <div
              key={inst.idDatoCert || index}
              className="bg-white border border-gray-200 rounded-xl shadow p-3 flex flex-col service-order-rounded"
            >
              <div className="mb-2">
                <h3 className="text-sm font-bold text-gray-700">
                  Instrumento {index + 1}{" "}
                  <span className="text-xs text-gray-400">#{inst.NoSerieInst}</span>
                </h3>
              </div>

              <label className="text-xs font-medium text-gray-600">
                Instrumento
              </label>
              <input
                className={`p-1 mb-1 border border-gray-300 rounded-xl mt-1 text-sm ${
                  readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
                value={inst.Instrumento || ""}
                onChange={(e) => handleChange(index, "Instrumento", e.target.value)}
                onBlur={() => handleBlur(index)}
                readOnly={readOnly}
              />

              <label className="text-xs font-medium text-gray-600">Marca</label>
              <input
                className={`p-1 mb-1 border border-gray-300 rounded-xl mt-1 text-sm ${
                  readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
                value={inst.Marca || ""}
                onChange={(e) => handleChange(index, "Marca", e.target.value)}
                onBlur={() => handleBlur(index)}
                readOnly={readOnly}
              />

              <label className="text-xs font-medium text-gray-600">Modelo</label>
              <input
                className={`p-1 mb-1 border border-gray-300 rounded-xl mt-1 text-sm ${
                  readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
                value={inst.Modelo || ""}
                onChange={(e) => handleChange(index, "Modelo", e.target.value)}
                onBlur={() => handleBlur(index)}
                readOnly={readOnly}
              />

              <label className="text-xs font-medium text-gray-600">No. Serie</label>
              <input
                className={`p-1 mb-1 border border-gray-300 rounded-xl mt-1 text-sm ${
                  readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
                value={inst.Serie || ""}
                onChange={(e) => handleChange(index, "Serie", e.target.value)}
                onBlur={() => handleBlur(index)}
                readOnly={readOnly}
              />

              <label className="text-xs font-medium text-gray-600">
                Ident. Cliente
              </label>
              <input
                className={`p-1 mb-1 border border-gray-300 rounded-xl mt-1 text-sm ${
                  readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
                value={inst.Identificacion || ""}
                onChange={(e) => handleChange(index, "Identificacion", e.target.value)}
                onBlur={() => handleBlur(index)}
                readOnly={readOnly}
              />

              <label className="text-xs font-medium text-gray-600">
                Intervalo de medida
              </label>
              <input
                className="p-1 mb-1 border border-transparent rounded-xl mt-1 bg-gray-100 text-gray-700 text-sm"
                disabled
                value={`${inst.IntervaloInicio || ""} - ${
                  inst.IntervaloFinal || ""
                } ${inst.Unidad || ""}`}
              />

              <label className="text-xs font-medium text-gray-600">
                Requisitos del cliente
              </label>
              <textarea
                rows={2}
                className="p-1 mb-2 border border-transparent rounded-xl mt-1 bg-gray-100 text-gray-700 text-sm"
                disabled
                value={inst.PuntosEsp || ""}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstrumentosStep;
