import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import FacturacionStep from "../../components/ServiceOrder/FacturacionStep";
import InstrumentosStep from "../../components/ServiceOrder/InstrumentosStep";
import CertificadoPreviewStep from "../../components/ServiceOrder/CertificadoPreviewStep";
import EncuestaCalidadStep from "../../components/ServiceOrder/EncuestaCalidadStep";
import { Layout } from "../../layouts/Layout";
import Swal from "sweetalert2";

const BACKEND_URL = "https://caltechnix-backend-gamma.vercel.app";

const ServiceOrderDetail = () => {
  const [searchParams] = useSearchParams();
  const idOrden = searchParams.get("id");

  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(1);

  // Datos principales
  const [datosFacturacion, setDatosFacturacion] = useState(null);
  const [instrumentos, setInstrumentos] = useState([]);

  // Fecha límite y estado de expiración
  const [fechaLimite, setFechaLimite] = useState(null);
  const [expired, setExpired] = useState(false);

  // Nuevo: para saber si la orden ya está confirmada
  const [confirmed, setConfirmed] = useState(false);

  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  // Banner normal de confirmación inicial
  const [showBanner, setShowBanner] = useState(true);
  const [closingBanner, setClosingBanner] = useState(false);

  useEffect(() => {
    if (!idOrden) return;

    const fetchData = async () => {
      setLoading(true);
      setErrorMsg("");

      try {
        // 1) Facturación
        const respFact = await fetch(`${BACKEND_URL}/api/datos-factura`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ idOrden }),
        });
        const dataFact = await respFact.json();

        if (!respFact.ok || dataFact.error) {
          setErrorMsg(dataFact.error || "Error al obtener datos de Facturación");
          setLoading(false);
          return;
        }

        setDatosFacturacion(dataFact);
        if (dataFact.fechaLimite) {
          setFechaLimite(dataFact.fechaLimite);
        }
        if (dataFact.expired) {
          setExpired(true);
        }

        // 2) Instrumentos
        const respInst = await fetch(`${BACKEND_URL}/api/datos-certificado`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ idOrden }),
        });
        const dataInst = await respInst.json();

        if (!respInst.ok || dataInst.error) {
          setErrorMsg(dataInst.error || "Error al obtener instrumentos");
          setLoading(false);
          return;
        }

        if (dataInst.confirmed) {
          setConfirmed(true);
        }

        let combinedInstruments = [];
        if (dataInst.instrumentos) {
          combinedInstruments = [...dataInst.instrumentos];
        }
        if (dataInst.instrumentosAdicional) {
          combinedInstruments = [
            ...combinedInstruments,
            ...dataInst.instrumentosAdicional,
          ];
        }
        setInstrumentos(combinedInstruments);

        if (dataInst.fechaLimite && !dataFact.fechaLimite) {
          setFechaLimite(dataInst.fechaLimite);
        }
        if (dataInst.expired) {
          setExpired(true);
        }
      } catch (error) {
        console.error(error);
        setErrorMsg("Error de conexión");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [idOrden]);

  // Aviso antes de salir (sólo si no está expirado y no está confirmado)
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (step < 4 && !expired && !confirmed) {
        e.preventDefault();
        e.returnValue =
          "Parece que no has terminado de confirmar toda la información. ¿Seguro que deseas salir?";
        return e.returnValue;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [step, expired, confirmed]);

  const handleCloseBanner = () => {
    setClosingBanner(true);
    setTimeout(() => setShowBanner(false), 500);
  };

  // Navegación
  const handleNextStep = () => setStep((prev) => prev + 1);
  const handlePrevStep = () => setStep((prev) => prev - 1);

  useEffect(() => {
    if (step > maxStep) setMaxStep(step);
  }, [step, maxStep]);

  // Actualizaciones
  const handleFacturacionUpdate = (updatedData) => {
    setDatosFacturacion(updatedData);
  };
  const handleInstrumentsUpdate = (updatedList) => {
    setInstrumentos(updatedList);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <p className="text-gray-600">Cargando información...</p>
      </div>
    );
  }
  if (errorMsg) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <p className="text-red-500">{errorMsg}</p>
      </div>
    );
  }

  let fechaLimiteStr = "";
  if (fechaLimite) {
    const d = new Date(fechaLimite);
    fechaLimiteStr = d.toLocaleString();
  }

  const ordenServicio = datosFacturacion?.OrdenServicio || idOrden;

  // OJO: Aquí quitamos "|| expired || confirmed" del disabled
  const renderStepButton = (i, label) => (
    <button
      onClick={() => setStep(i)}
      disabled={i > maxStep /* antes: i > maxStep || expired || confirmed */}
      className={`w-full text-left py-2 px-3 rounded-xl transition-colors ${
        step === i
          ? "bg-blue-100 text-blue-700 font-semibold"
          : i <= maxStep
          ? "hover:bg-gray-100 text-gray-600"
          : "text-gray-300 cursor-not-allowed"
      }`}
    >
      {step === i ? "➡" : i < step ? "✔" : ""} {label}
    </button>
  );

  // Si la orden ya está confirmada o está expirada, pero queremos
  // permitir navegar, solo mostramos un banner y luego "Ver datos en modo lectura".
  if ((expired || confirmed) && showBanner) {
    return (
      <Layout header={2} footer={1}>
        <div className="banner-backdrop">
          <div className="banner-content fade-in">
            <h1 className="text-2xl font-bold text-red-500 mb-4">
              {confirmed ? "Orden Confirmada" : "Enlace Expirado"}
            </h1>
            {confirmed ? (
              <p className="text-gray-700 mb-4">
                Esta orden ya fue confirmada y no se pueden realizar más cambios.
                Puedes consultar la información en modo lectura.
              </p>
            ) : (
              <p className="text-gray-700 mb-4">
                Lo sentimos, la fecha límite de confirmación ha pasado (
                <strong>{fechaLimiteStr}</strong>).
                Solo podrás consultar.
              </p>
            )}
            <button
              className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded"
              onClick={() => setShowBanner(false)}
            >
              Ver datos en modo lectura
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout header={2} footer={1}>
      {/* BANNER DE CONFIRMACIÓN: sólo si NO está expirado y NO está confirmado */}
      {showBanner && !expired && !confirmed && (
        <div className="banner-backdrop">
          <div className={`banner-content ${closingBanner ? "fade-out" : ""}`}>
            <h1 className="text-2xl font-bold text-red-500 mb-4">¡IMPORTANTE!</h1>
            <p className="text-gray-700 mb-4">
              Los datos siguientes se usan para el procesamiento de su calibración
              y aparecerán así en el certificado. Verifícalos cuidadosamente.
            </p>
            <p className="text-gray-700 mb-4 font-semibold">
              Tienes hasta el <strong>{fechaLimiteStr || "..."}</strong> para confirmar
              (2 días hábiles). Pasada esa fecha, no se permitirá modificar la información.
            </p>
            <p className="text-gray-700 mb-4">
              Si no respondes a tiempo, se procederá con la información que tenemos y
              cualquier modificación posterior podría generar costos adicionales.
            </p>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
              onClick={handleCloseBanner}
            >
              He leído y acepto iniciar el proceso
            </button>
          </div>
        </div>
      )}

      {/* Layout principal */}
      <div className="h-[660px] bg-gray-50 p-8 flex gap-6 rounded-lg service-order-bg">
        {/* Sidebar */}
        <div className="w-64 bg-white rounded-2xl shadow-xl p-6 h-full service-order-rounded flex flex-col">
          <h2 className="text-xl font-bold mb-4 text-gray-700">Pasos</h2>
          <div className="flex-1 flex flex-col space-y-2 overflow-y-auto">
            {renderStepButton(1, "1. Facturación")}
            {renderStepButton(2, "2. Certificado de Calibración")}
            {renderStepButton(3, "3. Vista Previa")}
            {renderStepButton(4, "4. Encuesta de Calidad")}
          </div>

          <div className="pt-4 border-t border-gray-100 flex flex-col space-y-3">
            {/* El botón Regresar / Siguiente siempre visible,
                aunque expired o confirmed, para que puedan navegar. */}
            {step > 1 && (
              <button
                onClick={handlePrevStep}
                className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded-xl"
              >
                Regresar
              </button>
            )}

            {step < 4 && (
              <button
                onClick={handleNextStep}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl"
              >
                Siguiente
              </button>
            )}
          </div>
        </div>

        {/* Contenedor de pasos (a la derecha) */}
        <div className="flex-1 h-full service-order-rounded overflow-hidden">
          <div className="bg-white service-order-rounded shadow-xl h-full flex flex-col">
            <div className="px-8 pb-8 flex-1 overflow-y-auto">
              {/* readOnly se basa en expired || confirmed */}
              {step === 1 && datosFacturacion && (
                <FacturacionStep
                  idOrden={idOrden}
                  ordenServicio={ordenServicio}
                  initialData={datosFacturacion}
                  onUpdateFacturacion={handleFacturacionUpdate}
                  readOnly={expired || confirmed}
                />
              )}

              {step === 2 && (
                <InstrumentosStep
                  idOrden={idOrden}
                  ordenServicio={ordenServicio}
                  instrumentos={instrumentos}
                  onUpdateInstruments={handleInstrumentsUpdate}
                  readOnly={expired || confirmed}
                />
              )}

              {step === 3 && (
                <CertificadoPreviewStep
                  idOrden={idOrden}
                  ordenServicio={ordenServicio}
                  instrumentos={instrumentos}
                  readOnly={expired || confirmed}
                />
              )}

              {step === 4 && (
                <EncuestaCalidadStep
                  idOrden={idOrden}
                  ordenServicio={ordenServicio}
                  instrumentos={instrumentos}
                  readOnly={expired || confirmed}
                  onConfirm={() => setConfirmed(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

//TODO

export default ServiceOrderDetail;
