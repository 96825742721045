import React from "react";
import { Link } from "react-router-dom";
import { W_LOGO } from "../../lib/assets";

export const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Información</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>Av. Río Churubusco #794, Ciudad de México, CDMX</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <a href="tel:+525556504414">+52 55 5650 4414</a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            Lun – Vie: 9 am – 6 pm, <br /> Sábado y Domingo:{" "}
                            <span>CERRADO</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Menú</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/">Inicio</Link>
                      </li>
                      <li>
                        <Link to="/about">Empresa</Link>
                      </li>
                      <li>
                        <Link to="/services">Servicios</Link>
                      </li>
                      <li>
                        <Link to="/project-details">Proyectos</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contacto</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Enlaces Rápidos</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/services/presion">
                          Calibración de presión
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/fuerza">Calibración de fuerza</Link>
                      </li>
                      <li>
                        <Link to="/services/temperatura">
                          Calibración de temperatura
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/masa">Calibración de masa</Link>
                      </li>
                      <li>
                        <Link to="/traceability">Trazabilidad</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Suscríbete a Nuestro Boletín</h4>
                  <div className="footer-newsletter">
                    <p>Regístrate en el boletín de CalTechnix.</p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="Introduce tu correo electrónico"
                      />
                      <button type="submit">Suscribirse</button>
                    </form>
                    <span>No enviamos spam</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="left-sider">
                  <div className="f-logo">
                    <Link to="/">
                      <img src={W_LOGO} alt="CalTechnix Logo" />
                    </Link>
                  </div>
                  <div className="copyright-text">
                    <p>
                      Copyright © CalTechnix | Todos los Derechos Reservados
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
