import React from "react";
import { ScrollToTopButton } from "../components/ScrollToTopButton/ScrollToTopButton";
import { HeaderServiceOrder } from "../components/Headers/HeaderServiceOrder";
import { Header } from "../components/Headers/Header";
import { FooterOne } from "../components/Footers/FooterOne";
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb";

export const Layout = ({ children, header, breadcrumb, title }) => {
  return (
    <>
      {/* Scroll-top  */}
      <ScrollToTopButton />

      {/* Header */}
      {header === 1 && <Header />}
      {header === 2 && <HeaderServiceOrder />}

      {/* Breadcrumb */}
      {breadcrumb && <Breadcrumb text={breadcrumb} title={title} />}

      {/* Children */}
      <main className="fix">{children}</main>

      {/* Footer */}
      <FooterOne />
    </>
  );
};
