import React from "react";
import { Link } from "react-router-dom";
import { ABOUT_AUTHOR, INNER_ABOUT_IMG03 } from "../../lib/assets";
const InfoPatterns = () => {
  return (
    <section className="about-area-eight pt-120 pb-16">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Columna de imagen principal */}
          <div className="col-lg-6 col-md-9">
            <div className="about-img-eight">
              <img src={INNER_ABOUT_IMG03} alt="Patrones de calibración" />
            </div>
          </div>
          {/* Columna de contenido */}
          <div className="col-lg-6">
            <div className="about-content-eight">
              <div className="section-title-two mb-30">
                <span className="sub-title">
                  Información de nuestros patrones
                </span>
                <h2 className="title">Trazabilidad de patrones</h2>
              </div>
              <p>
                En esta sección encontrará los informes de calibración de
                nuestros patrones que usamos en la calibración de sus
                instrumentos. Estos datos son actualizados en vivo por nuestro
                sistema ERP AdCalib.
              </p>
              <p className="font-extrabold underline">
                ¿Cómo encuentro el patrón con el cual fue calibrado mi
                instrumento?
              </p>
              <p>
                En el informe de calibración que le hemos emitido encontrará el
                apartado <strong>Patrones y condiciones de prueba</strong>. En
                éste indicamos los principales datos de nuestros patrones
                utilizados, incluyendo la identificación y el informe de
                calibración. Use estos datos para buscar y localizar el patrón
                en la tabla siguiente.
              </p>
              <p>
                La identificación del informe es un enlace al respectivo
                Certificado de calibración (solamente publicamos los
                certificados recientes).
              </p>

              <div className="about-content-bottom mt-4">
                <div className="services-btn">
                  <Link
                    to="#"
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault(); // Evita que Link intente cambiar de ruta
                      const target = document.getElementById("patterns-table");
                      if (target) {
                        target.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Ver Patrones
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Fin columna de contenido */}
        </div>
      </div>
    </section>
  );
};

export default InfoPatterns;
