import React from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { LOGO } from "../../lib/assets";
import { MobileMenu } from "./MobileMenu";
import { NavMenus } from "./NavMenus";
import { useStickyMenu } from "../../lib/hooks/useStickyMenu";
import { FaWhatsapp } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import { MdMailOutline } from "react-icons/md";

export const Header = () => {
  // menu sticky
  useStickyMenu();

  return (
    <>
      <div id="header-fixed-height"></div>

      <header className="header-style-four">
        <div className="heder-top-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="header-top-left">
                  <ul className="list-wrap">
                    <li>
                      <i><MdMailOutline /></i>
                      <a href="mailto:caltechnix@caltechnix.com.mx">
                        caltech@caltechnix.com.mx
                      </a>
                    </li>
                    <li>
                      <i><IoCallOutline /></i>
                      <a href="tel:+525556504414">+52 55 5650 4414</a>
                    </li>
                    <li>
                      <i><FaWhatsapp /></i>
                      <a href="https://wa.me/525614115353">56 1411 5353</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="header-top-right">
                  <div className="header-top-btn">
                    <Link to="/contact">
                      <i className="flaticon-briefcase"></i>Obtener una cotización
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sticky-header" className="menu-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler mt-3">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <Link to="/">
                        <img src={LOGO} alt="Logo" />
                      </Link>
                    </div>

                    {/* nav links */}
                    <NavMenus />

                    <div className="header-action mt-3">
                      <ul className="list-wrap">
                        <li className="offcanvas-menu offcanvas-menu-two">
                          <a href="#" className="menu-tigger">
                            <span></span>
                            <span></span>
                            <span></span>
                          </a>
                        </li>
                        <li className="header-contact-two">
                          <div className="icon">
                            <i><FaWhatsapp /></i>
                          </div>
                          <div className="content">
                            <span>WhatsApp</span>
                            <a href="https://wa.me/525614115353">56 1411 5353</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>

                {/* Mobile Menu  */}
                <MobileMenu />
              </div>
            </div>
          </div>
        </div>

        {/* sidebar */}
        <Sidebar />
      </header>
    </>
  );
};
