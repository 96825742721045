import React, { useEffect, useState } from "react";

const PatternsTable = () => {
  // Estados para manejar datos, paginación, tamaño de página y búsqueda
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Tamaño de página inicial en 10
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Función para llamar a la API
  const fetchData = async () => {
    setLoading(true);
    setMessage("");
    try {
      const response = await fetch("https://caltechnix-backend-gamma.vercel.app/api/datos-trazabilidad", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page,
          pageSize,
          identificacion: searchTerm ? searchTerm : undefined,
        }),
      });
      const result = await response.json();

      if (result.message) {
        // Si el backend manda un message de "No hay registros"
        setMessage(result.message);
        setData([]);
        setTotal(0);
        setTotalPages(0);
      } else {
        setData(result.data || []);
        setTotal(result.total || 0);
        setTotalPages(result.totalPages || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage("Error al obtener datos");
    } finally {
      setLoading(false);
    }
  };

  // Llamar a la API cuando cambien `page`, `searchTerm` o `pageSize`
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchTerm, pageSize]);

  // Al buscar, volvemos a la primera página
  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  // Manejar cambio en el tamaño de página
  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPage(1); // Reiniciamos la página al cambiar el tamaño
  };

  // Botones Previa / Siguiente
  const handlePrev = () => {
    if (page > 1) setPage(page - 1);
  };
  const handleNext = () => {
    if (page < totalPages) setPage(page + 1);
  };

  return (
    <section id="patterns-table" className="bg-white py-8 pb-16">
      <div className="container mx-auto px-4">
        <div className="shadow-lg rounded overflow-hidden">
          {/* Barra de búsqueda y selección de tamaño */}
          <div className="p-4 bg-gray-50 flex flex-wrap items-center justify-between">
            <div className="flex items-center">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Buscar identificador exacto"
                className="border border-gray-300 rounded-l px-4 py-2 focus:outline-none focus:ring-1 focus:ring-blue-400"
              />
              <button
                onClick={handleSearch}
                className="px-4 py-2 bg-[var(--tg-primary-color)] text-white rounded-r hover:bg-blue-600 transition"
                style={{ marginLeft: "-1px" }}
              >
                Buscar
              </button>
            </div>
            <div className="flex items-center mt-2 sm:mt-0">
              <label htmlFor="pageSize" className="mr-2 text-sm text-gray-700">
                Tamaño de página:
              </label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={handlePageSizeChange}
                className="border border-gray-300 rounded px-5 py-1 focus:outline-none focus:ring-1 focus:ring-blue-400"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>

          {/* Contenedor de la tabla con altura fija y scroll */}
          <div className="overflow-auto" style={{ height: "390px" }}>
            {loading ? (
              <div className="p-4 text-center">Cargando...</div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[var(--tg-primary-color)] sticky top-0 z-10">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
                      Descripción
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
                      Marca
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
                      Identificación
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
                      Calibrado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
                      Próxima
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
                      Informe
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {message ? (
                    <tr>
                      <td
                        colSpan={6}
                        className="px-6 py-4 text-center text-sm text-gray-800"
                      >
                        {message}
                      </td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {item.Descripcion}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {item.Marca}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[var(--tg-secondary-color)]">
                          {item.Identificacion}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {item.Calibrado ? item.Calibrado.substring(0, 10) : ""}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {item.Proxima ? item.Proxima.substring(0, 10) : ""}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm"
                          dangerouslySetInnerHTML={{ __html: item.Informe || "" }}
                        />
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>

          {/* Pie de tabla con paginación */}
          <div className="px-6 py-6 bg-gray-50 flex flex-col sm:flex-row sm:justify-between items-center">
            <span className="text-sm text-gray-700">
              Mostrando página {page} de {totalPages}. Total: {total}.
            </span>
            <div className="mt-2 sm:mt-0">
              <button
                onClick={handlePrev}
                disabled={page <= 1}
                className="px-4 py-2 bg-[var(--tg-primary-color)] text-white rounded hover:bg-blue-600 transition mr-2 disabled:opacity-50"
              >
                Previa
              </button>
              <button
                onClick={handleNext}
                disabled={page >= totalPages}
                className="px-4 py-2 bg-[var(--tg-primary-color)] text-white rounded hover:bg-blue-600 transition disabled:opacity-50"
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatternsTable;