import React from "react";
import { Layout } from "../../layouts/Layout";
import { AboutUs } from "../../components/About/AboutUs";
import { Brands } from "../../components/Brand/Brands";
import { ChooseAreaAboutUs } from "../../components/ChooseAreas/ChooseAreaAboutUs";
import ProjectDetails from "../ProjectDetails/project-details";

const AboutPage = () => {
  return (
    <Layout header={1} footer={1} breadcrumb={"empresa"} title={"Empresa"}>
      {/* about-area */}
      <ProjectDetails/>
      <AboutUs />
      <ChooseAreaAboutUs />
      <Brands />
    </Layout>
  );
};

export default AboutPage;
