import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SERVICES_DETAILS03,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const FuerzaServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Calibración de fuerza"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            {/* TÍTULO PRINCIPAL */}
            <h2 className="title">Calibración en el área de fuerza</h2>
            
            {/* DESCRIPCIÓN PRINCIPAL */}
            <p>
              Nuestro servicio especializado de calibración en fuerza cubre
              equipos como celdas de carga, dinamómetros, tensiómetros y
              máquinas de ensayo, brindando mediciones precisas para asegurar
              la calidad y eficiencia de sus procesos industriales. 
              Aplicamos metodologías confiables que reducen riesgos 
              de fallas y mejoran la seguridad en sus líneas de producción.
            </p>
            <p>
              Al comparar los valores de su equipo con patrones reconocidos,
              garantizamos trazabilidad y consistencia en los resultados. 
              Bajo la acreditación ISO17025, ratificada por la entidad mexicana
              de acreditación (F-28), validamos cada calibración para
              ofrecerle un respaldo técnico sólido y una completa confianza
              en su proceso de medición.
            </p>

            {/* BLOQUE INTERNO */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      {/* PRIMER ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de instrumentos de fuerza</h5>
                          <p>
                            Ajustamos y verificamos la exactitud de celdas de
                            carga, dinamómetros, anillos patrón y equipos
                            similares, asegurando resultados confiables.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* CONTENIDO ADICIONAL - DERECHA */}
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">¿Por qué elegir CalTechnix?</h3>
                    <p>
                      Nuestro equipo de metrólogos combina amplia experiencia
                      con reconocimientos y auditorías periódicas, 
                      lo que nos permite seguir las mejores prácticas a nivel
                      internacional. Aplicamos métodos rigurosos para asegurar
                      que cada pieza de equipo mida la fuerza de manera
                      confiable y uniforme, sin importar las exigencias de
                      su entorno de trabajo.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Profesionales avalados por la entidad mexicana de acreditación
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Calibraciones trazables bajo norma ISO17025
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Flexibilidad: en planta o en laboratorio
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Mayor confiabilidad en sus procesos de ensayo
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* SECCIÓN DE EXPERIENCIA Y AVAL */}
            <div className="industrial-working-wrap">
              <h2 className="title-two">Experiencia y Aval en Calibración de Fuerza</h2>
              <p className="info-one">
                Nuestra acreditación F-28, expedida por la entidad mexicana de
                acreditación, respalda nuestra competencia para calibrar 
                celdas de carga, dinamómetros, tensiómetros y máquinas universales 
                de ensayo. Desde el 14 Nov 2011 operamos bajo normas 
                internacionales, manteniendo la vigencia de forma indefinida 
                y ratificada hasta el 16 Oct 2024.
              </p>
              <p className="info-two">
                Con CalTechnix, usted obtiene el respaldo de un laboratorio 
                con procesos auditados y la tranquilidad de resultados 
                precisos. Buscamos mejorar la continuidad de sus líneas de 
                producción y asegurar la calidad final de sus productos, 
                entregándole la certeza de que cada medición de fuerza es 
                totalmente confiable.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default FuerzaServicePage;
