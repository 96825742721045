import React from "react";
import { SERVICES_PAGE_THREE_LIST } from "../../mocks/services";
import { ServicePageItem } from "./ServicePageItem";

export const ServicePage = () => {
  const list = [...SERVICES_PAGE_THREE_LIST, ...SERVICES_PAGE_THREE_LIST];
  list.length = 7;

  return (
    <section className="services-area-five inner-services-bg">
      <div className="container">
        <div className="row justify-content-center">
          {list.map((service) => (
            <div key={service.id} className="col-lg-4 col-md-6 col-sm-10">
              <ServicePageItem service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
