import React, { useState } from "react";
import Swal from "sweetalert2";
import { ContactAreaMap } from "./ContactAreaMap";

export const ContactAreaInner = () => {
  // Estados para el formulario
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [enviando, setEnviando] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nombre.trim() || !email.trim() || !mensaje.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Faltan datos",
        text: "Por favor, completa todos los campos antes de enviar.",
      });
      return;
    }

    setEnviando(true);

    try {
      const resp = await fetch("https://caltechnix-backend-gamma.vercel.app/api/contacto", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ nombre, email, mensaje }),
      });

      if (!resp.ok) {
        const dataError = await resp.json();
        throw new Error(dataError.error || "Error al enviar");
      }

      Swal.fire({
        icon: "success",
        title: "Mensaje enviado",
        text: "Gracias. Tu mensaje ha sido enviado correctamente.",
        confirmButtonColor: "#1d74b8",
      });

      // Limpiar campos
      setNombre("");
      setEmail("");
      setMensaje("");
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "No se pudo enviar tu mensaje.",
        confirmButtonColor: "#1d74b8",
      });
    } finally {
      setEnviando(false);
    }
  };

  return (
    <section className="inner-contact-area pt-100 pb-100">
      <div className="container mx-auto px-4">
        {/* Grid principal con 2 filas */}
        <div className="grid grid-cols-1 gap-8">
          
          {/* Fila 1: Formulario */}
          <div>
            <div className="p-6 border border-gray-300 rounded shadow-sm">
              <h2 className="text-2xl font-bold mb-4">Envíanos tu mensaje</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Nombre</label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="Tu nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="Tu email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Mensaje</label>
                  <textarea
                    className="w-full p-2 border border-gray-300 rounded"
                    rows="5"
                    placeholder="Escribe tu mensaje"
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="btn mt-3"
                  disabled={enviando}
                >
                  {enviando ? "Enviando..." : "Enviar mensaje"}
                </button>
              </form>
            </div>
          </div>

          {/* Fila 2: 2 columnas (dirección + mapa) 
              items-center => centra verticalmente los contenidos en las columnas */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
            
            {/* Columna 1: Dirección */}
            <div className="p-6 border border-gray-300 rounded shadow-sm h-full flex flex-col justify-center">
            <div className="inner-contact-info mr-6">
              <h2 className="title">Nuestra dirección</h2>
              <div className="contact-info-item">
                <ul className="list-wrap">
                  <li>
                  Av. Río Churubusco #794, Col. El Sifón, Alcaldía Iztapalapa <br />
                  CP 09400, Ciudad de México, CDMX
                  </li>
                  <li>(+55) 5650 4414</li>
                  <li>caltech@caltechnix.com.mx</li>
                </ul>
              </div>
            </div>
          
            </div>

            {/* Columna 2: Mapa */}
            <div className="p-6 border border-gray-300 rounded shadow-sm ">
              <ContactAreaMap />
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};
