import React from "react";
import { Layout } from "../../layouts/Layout";
import ProjectDetails from "./project-details";

const ProjectDetailsPage = () => {
  return (
    <Layout breadcrumb={"Portfolio Details"} title={"Portfolio Details"}>
      <ProjectDetails/>
    </Layout>
  );
};

export default ProjectDetailsPage;
