import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const BACKEND_URL = "https://caltechnix-backend-gamma.vercel.app";

const FacturacionStep = ({
  idOrden,
  ordenServicio,
  initialData,
  onUpdateFacturacion,
  readOnly = false,
}) => {
  const [formData, setFormData] = useState(initialData);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleSaveToServer = async (updatedData) => {
    if (readOnly) return;

    setIsSaving(true);
    try {
      const resp = await fetch(`${BACKEND_URL}/api/datos-factura`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          idOrden,
          ...updatedData,
        }),
      });

      if (resp.status === 403) {
        const data403 = await resp.json();
        Swal.fire({
          icon: "error",
          title: "No se puede actualizar",
          text: data403.error || "La fecha límite ha pasado o la orden ya fue confirmada.",
          confirmButtonColor: "#1d74b8",
        });
        return;
      }

      const data = await resp.json();
      if (!resp.ok || data.error) {
        Swal.fire({
          icon: "error",
          title: "Error al actualizar",
          text: data.error || "Ocurrió un problema.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Datos de factura guardados",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
        });

        if (onUpdateFacturacion) {
          onUpdateFacturacion(updatedData);
        }
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error de conexión",
        text: "No se pudo conectar con el servidor.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (e) => {
    if (readOnly) return;
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = () => {
    if (!readOnly) {
      handleSaveToServer(formData);
    }
  };

  const handleManualSave = () => {
    if (!readOnly) {
      handleSaveToServer(formData);
    }
  };

  const fields = [
    { label: "RFC", name: "RFC" },
    { label: "Empresa", name: "Empresa" },
    { label: "Correo", name: "Correo" },
    { label: "Dirección", name: "Direccion" },
    { label: "Ciudad", name: "Ciudad" },
    { label: "Municipio/Alcaldía", name: "Municipio" },
    { label: "Estado", name: "Estado" },
    { label: "Código Postal", name: "CP" },
    { label: "Pedido", name: "Pedido" },
    { label: "Método de Pago", name: "MetodoPago" },
    { label: "Forma de Pago", name: "FormaPago" },
    { label: "Uso de CFDI", name: "UsoCFDI" },
  ];

  return (
    <div className="relative flex flex-col h-full">
      <div className="sticky top-0 z-10 bg-white">
        <div className="px-8 pt-8 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-700 mb-2">
              ID de Orden:{" "}
              <span className="text-blue-600">{ordenServicio}</span>
            </h1>
            <p className="text-sm text-gray-500 mb-4">
              Los datos siguientes se usarán para la factura de su servicio.
            </p>
            <div className="border-b border-gray-200 mb-2" />
          </div>

          {!readOnly && (
            <button
              onClick={handleManualSave}
              disabled={isSaving}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-xl text-sm flex items-center justify-center"
              style={{ minWidth: "100px", borderRadius: "1rem" }}
            >
              {isSaving ? "Guardando..." : "Guardar cambios"}
            </button>
          )}
        </div>
      </div>

      <div className="px-8 pt-4 pb-24 flex-1 overflow-y-auto">
        <h2 className="text-lg font-semibold text-gray-700">Datos de Facturación</h2>

        <div className="grid grid-cols-2 gap-4 mt-4">
          {fields.map((field) => (
            <div key={field.name} className="flex flex-col">
              <label className="text-xs font-medium text-gray-600">
                {field.label}
              </label>
              <input
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={readOnly}
                className={`p-1 border border-gray-300 rounded-xl mt-1 text-sm ${
                  readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FacturacionStep;
