import React from "react";
import { INNER_CHOOSE_IMG } from "../../lib/assets";

export const ChooseAreaAboutUs = () => {
  return (
    <section className="choose-area-three pt-120 pb-120">
      <div className="container">
        <div className="row">
          {/* Columna de texto e información */}
          <div className="col-lg-6">
            <div className="choose-content-three">
              <div className="section-title-two white-title mb-30">
                {/* Subtítulo y Título, ahora en español */}
                <span className="sub-title">Nuestras Especialidades</span>
                <h2 className="title">
                Soluciones de calibración
                </h2>
              </div>

              <p>
                Con más de 30 años de experiencia en metrología, <strong>CalTechnix</strong> ofrece 
                servicios de calibración acreditados por la <strong>Entidad Mexicana de Acreditación (EMA)</strong> 
                y reconocidos internacionalmente por <strong>ILAC</strong>. Gracias a ello, garantizamos 
                mediciones confiables y operatividad continua para tu negocio.
              </p>

              {/* Acordeón principal */}
              <div className="accordion-wrap-two">
                <div className="accordion" id="accordionExample">

                  {/* Ítem 1: Reconocimientos y Acreditaciones */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Reconocimientos y Acreditaciones
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                      style={{visibility: "visible"}}
                    >
                      <div className="accordion-body">
                        <p>
                          Desde 1992, CalTechnix ofrece soluciones de 
                          calibración en magnitudes como presión, fuerza 
                          y par torsional. Nuestros servicios están avalados 
                          por la <strong>EMA (ISO 17025)</strong> y forman parte 
                          de acuerdos <strong>ILAC</strong>, 
                          brindándote validez internacional y confianza en cada medición.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Ítem 2: Plataforma AdCalib ERP */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Plataforma AdCalib ERP
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                      style={{visibility: "visible"}}
                    >
                      <div className="accordion-body">
                        <p>
                          Nuestro software <strong>AdCalib</strong> gestiona 
                          la trazabilidad de calibraciones, agiliza los 
                          procesos y asegura el cumplimiento de la norma 
                          <strong>ISO 17025</strong>. Con esta plataforma 
                          integral, cuidamos la disponibilidad de tus equipos 
                          y evitamos tiempos muertos innecesarios.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Ítem 3: Ética y Garantía de Calidad */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Ética y Garantía de Calidad
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                      style={{visibility: "visible"}}
                    >
                      <div className="accordion-body">
                        <p>
                          Nos regimos por un sólido <em>Código de Ética</em> 
                          que promueve integridad, imparcialidad y el uso 
                          responsable de los recursos. Así, aseguramos 
                          servicios confiables, reducimos impactos al 
                          medio ambiente y aportamos valor real a cada uno 
                          de nuestros clientes.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Fin acordeón */}
                </div>
              </div>
            </div>
          </div>

          {/* Columna de imagen */}
          <div className="col-lg-6">
            <div className="choose-img-three">
              <img src={INNER_CHOOSE_IMG} alt="CalTechnix" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
