import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { LOGO } from "../../lib/assets";
import { CiMenuFries } from "react-icons/ci";

export const Sidebar = () => {
  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
  }, []);

  return (
    <>
      <div className="extra-info">
        <div className="close-icon menu-close">
          <button>
          <CiMenuFries />
          </button>
        </div>
        <div className="logo-side mb-30">
          <Link to="/">
            <img src={LOGO} alt="Logo" />
          </Link>
        </div>
        <div className="side-info mb-30">
          <div className="contact-list mb-30">
            <h4>Dirección</h4>
            <p>
              Av. Río Churubusco #794, Col. El Sifón, Alcaldía Iztapalapa <br />
              CP 09400, Ciudad de México, CDMX 
            </p>
          </div>
          <div className="contact-list mb-30">
          <h4>Líneas de contacto</h4>
               <p>55 5650 4414</p>
          </div>
          <div className="contact-list mb-30">
          <h4>Nuestro WhatsApp</h4>
               <p>56 1411 5353</p>
          </div>
          <div className="contact-list mb-30">
            <h4>Dirección de correo electrónico</h4>
            <p>caltech@caltechnix.com.mx</p>
          </div>
        </div>
      </div>
      <div className="offcanvas-overly"></div>
    </>
  );
};
