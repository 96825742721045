import React from "react";
import { Layout } from "../../layouts/Layout";
import { TRAZABILIDAD_IMG } from "../../lib/assets";
import { Brands } from "../../components/Brand/Brands";

const TraceabilityPage = () => {
  return (
    <Layout breadcrumb={"trazabilidad"} title={"Trazabilidad"} header={1}>
      {/* team-details-area */}
      <section className="team-details-area pt-12 pb-12">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
          
          {/* Imagen */}
          <div className="flex justify-center items-center">
            <div className="team-details-info-wrap">
              <div className="team-details-thumb">
                <img src={TRAZABILIDAD_IMG} alt="Trazabilidad" className="max-w-full h-auto" />
              </div>
            </div>
          </div>

          {/* Contenido textual */}
          <div className="team-details-content">
            <h2 className="title text-3xl font-semibold mb-4">¿Qué es la trazabilidad?</h2>
            <span className="text-lg text-gray-600 mb-6">Según la ISO 9001:2015</span>
            <p className="mb-6">
              La trazabilidad en medición es la capacidad de vincular un resultado con estándares nacionales o internacionales mediante una cadena ininterrumpida de comparaciones, todas con incertidumbre especificada (ISO 9001:2015). Sin embargo, el simple hecho de contar con certificados de calibración no garantiza la trazabilidad ni la exactitud de una medición. Para que una medición sea confiable, es necesario comprender el sistema medido, aplicar correctamente los procedimientos y considerar la incertidumbre en cada paso del proceso.
            </p>

            {/* Errores que rompen la trazabilidad */}
            <div className="team-skill-wrap mb-6">
              <h3 className="title-two text-2xl font-semibold mb-4">Errores que rompen la trazabilidad</h3>
              <p>
                Uno de los errores más comunes que afectan la trazabilidad es el uso inadecuado del instrumento, la ausencia de procedimientos correctos y la falta de cálculo de incertidumbres. No basta con tener una calibración certificada; el usuario es responsable de garantizar la continuidad de la trazabilidad en sus propias mediciones. Factores como el desconocimiento de tolerancias, la selección inadecuada de equipos o el uso de instrumentos dañados pueden comprometer los resultados, generando mediciones inexactas y poco confiables.
              </p>
            </div>
          </div>
        </div>

        {/* Requisitos para asegurar trazabilidad */}
        <div className="container mt-12 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="team-details-info-wrap">
            <h3 className="title-two text-2xl font-semibold mb-4">Para asegurar la trazabilidad se requiere:</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li><strong>Confianza en las lecturas del instrumento:</strong> Basada en la calibración adecuada y la verificación periódica.</li>
              <li><strong>Conocimiento del sistema y entorno de la medición:</strong> Considerando factores ambientales y operativos que puedan afectar los resultados.</li>
              <li><strong>Aplicación correcta de procedimientos de medición:</strong> Seguir métodos estandarizados para reducir errores.</li>
              <li><strong>Competencia del metrólogo:</strong> Habilidad y juicio profesional en la interpretación de resultados.</li>
            </ul>
          </div>

          <div className="team-details-info-wrap">
            <h3 className="title-two text-2xl font-semibold mb-4">Causas comunes de ruptura de trazabilidad:</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li><strong>Uso inadecuado del instrumento.</strong></li>
              <li><strong>Falta de procedimientos adecuados.</strong></li>
              <li><strong>Desconocimiento de la incertidumbre de medición.</strong></li>
              <li><strong>Condiciones ambientales inapropiadas.</strong></li>
            </ul>
          </div>
        </div>

        {/* Conclusiones y Recomendaciones */}
        <div className="container mt-12">
          <h3 className="title-two text-2xl font-semibold mb-4">Conclusiones y Recomendaciones:</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li><strong>“Medir lo menos posible, pero lo mínimo necesario”</strong> para optimizar recursos sin comprometer la calidad.</li>
            <li><strong>Calibrar los instrumentos en laboratorios acreditados</strong> para garantizar confiabilidad.</li>
            <li><strong>La calibración debe considerarse una inversión</strong> que mejora la credibilidad de los resultados y reduce el riesgo de productos defectuosos.</li>
          </ul>
        </div>


      </section>
      <Brands/>
    </Layout>
  );
};

export default TraceabilityPage;
