import React, { useState } from "react";
import Swal from "sweetalert2";

const BACKEND_URL = "https://caltechnix-backend-gamma.vercel.app";

/**
 * Paso final de confirmación:
 *  - Se actualiza FechaModifico sólo aquí (encuesta).
 *  - Muestra #NoFolio (tomado de idDatoCert de algún instrumento).
 */
const EncuestaCalidadStep = ({
  idOrden,
  ordenServicio,
  instrumentos,
  readOnly = false,
  onConfirm = () => {},
}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [comentarios, setComentarios] = useState("");
  const [isSending, setIsSending] = useState(false);

  const handleEnviarEncuesta = async () => {
    if (readOnly) return;
    setIsSending(true);

    try {
      const resp = await fetch(`${BACKEND_URL}/api/update-encuesta-bitacora`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          idOrden,
          califica: rating,
          comentarios,
        }),
      });

      if (resp.status === 403) {
        const data403 = await resp.json();
        Swal.fire({
          icon: "error",
          title: "No se puede enviar la encuesta",
          text: data403.error,
          confirmButtonColor: "#1d74b8",
        });
        setIsSending(false);
        return;
      }

      const data = await resp.json();
      if (!resp.ok || data.error) {
        Swal.fire({
          icon: "error",
          title: "Error al enviar encuesta",
          text: data.error || "Ocurrió un problema.",
          confirmButtonColor: "#1d74b8",
        });
      } else {
        const folio = instrumentos?.[0]?.idDatoCert || "(sin folio)";

        Swal.fire({
          icon: "success",
          title: "¡Gracias por tu retroalimentación!",
          text: `Folio de confirmación: #${folio}`,
          confirmButtonColor: "#1d74b8",
        });

        onConfirm();
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error de conexión",
        text: "No se pudo conectar con el servidor.",
        confirmButtonColor: "#1d74b8",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="relative">
      <div className="px-8 pt-8 flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-700 mb-2">
            ID de Orden:{" "}
            <span className="text-blue-600">{ordenServicio}</span>
          </h1>
          <p className="text-sm text-gray-500 mb-4">
            Tu opinión es muy importante para nosotros. ¡Evalúa tu experiencia!
          </p>
          <div className="border-b border-gray-200 mb-2" />
        </div>
      </div>

      <div className="space-y-4 py-4 px-8">
        <h2 className="text-lg font-semibold text-gray-700">Encuesta de Calidad</h2>
        <p className="text-xs text-gray-500">
          Tu opinión es muy importante para nosotros. ¡Evalúa tu experiencia!
        </p>

        <div className="flex items-center gap-1">
          {Array.from({ length: 5 }).map((_, index) => {
            const starValue = index + 1;
            return (
              <button
                key={starValue}
                type="button"
                className="cursor-pointer outline-none bg-transparent"
                onClick={() => {
                  if (!readOnly) setRating(starValue);
                }}
                onMouseEnter={() => !readOnly && setHover(starValue)}
                onMouseLeave={() => !readOnly && setHover(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill={starValue <= (hover || rating) ? "#facc15" : "none"}
                  viewBox="0 0 24 24"
                  stroke="#facc15"
                  className="w-8 h-8"
                  strokeWidth={1.5}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.75.75 0 011.04 0l2.192 2.224 
                     3.089.57a.75.75 0 01.41 1.278l-2.186 2.29.56 3.297
                     a.75.75 0 01-1.085.79L12 12.347l-2.694 1.602
                     a.75.75 0 01-1.085-.79l.56-3.297-2.186-2.29
                     a.75.75 0 01.41-1.278l3.09-.57 2.185-2.224z"
                  />
                </svg>
              </button>
            );
          })}
        </div>

        <div className="flex flex-col">
          <label className="text-xs font-medium text-gray-600">Comentarios</label>
          <textarea
            className={`p-2 border border-gray-300 rounded-xl mt-1 text-sm ${
              readOnly ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
            }`}
            rows={3}
            value={comentarios}
            onChange={(e) => !readOnly && setComentarios(e.target.value)}
            placeholder="¿Qué nos quieres comentar sobre nuestro servicio?"
            readOnly={readOnly}
          />
        </div>
      </div>

      {!readOnly && (
        <div className="sticky bottom-0 left-0 w-full bg-white p-4 z-50 shadow-inner flex justify-end">
          <button
            onClick={handleEnviarEncuesta}
            style={{ borderRadius: "1rem" }}
            disabled={isSending || rating === 0}
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 text-sm"
          >
            {isSending ? "Enviando..." : "Enviar encuesta"}
          </button>
        </div>
      )}
    </div>
  );
};

export default EncuestaCalidadStep;
