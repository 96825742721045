import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SERVICES_DETAILS03,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const PresionServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Calibración de presión"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            {/* TÍTULO PRINCIPAL */}
            <h2 className="title">Calibración en el área de presión</h2>
            {/* DESCRIPCIÓN PRINCIPAL */}
            <p>
              Nuestra especialidad en el servicio de presión abarca el
              mantenimiento y la verificación metrológica de manómetros,
              transductores, balanzas de presión y diversos equipos. El objetivo
              es brindar mediciones exactas que apoyen la seguridad industrial,
              la eficiencia de sus procesos y el cumplimiento de normas
              oficiales.
            </p>
            <p>
              Al comparar los valores de un instrumento con patrones de
              referencia, aseguramos que su sistema de medición sea confiable.
              Bajo la acreditación ISO17025, ratificada por la entidad mexicana
              de acreditación (P-36), validamos cada calibración para ofrecerle
              la máxima trazabilidad y respaldo técnico.
            </p>

            {/* BLOQUE INTERNO */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      {/* PRIMER ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de instrumentos de presión</h5>
                          <p>
                            Verificamos la exactitud de manómetros, transductores y
                            balanzas de presión, asegurando mediciones confiables.
                          </p>
                        </div>
                      </li>

                      {/* SEGUNDO ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración en laboratorio y en planta</h5>
                          <p>
                            Ofrecemos calibraciones tanto en nuestro laboratorio como
                            en sus instalaciones, minimizando tiempos de inactividad.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* CONTENIDO ADICIONAL - DERECHA */}
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">¿Por qué elegir CalTechnix?</h3>
                    <p>
                      Nuestros expertos en metrología cuentan con años de experiencia en
                      la calibración de presión, respaldados por reconocimientos y auditorías
                      constantes. Llevamos a cabo procedimientos exhaustivos, siguiendo
                      estándares internacionales, para asegurar la precisión de sus instrumentos
                      y salvaguardar la operatividad de sus sistemas. Gracias a nuestra sólida trayectoria,
                      garantizamos la continuidad de sus procesos productivos y reducimos al mínimo
                      los riesgos de fallas o accidentes relacionados con la presión.
                      Contamos con las herramientas más avanzadas para diagnosticar y calibrar
                      diversos tipos de manómetros y sistemas de medición.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Expertos avalados por la entidad mexicana de acreditación
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Equipos calibrados bajo norma ISO17025
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Procesos confiables y trazables
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Mayor seguridad en la operación
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* SECCIÓN DE EXPERIENCIA Y AVAL */}
            <div className="industrial-working-wrap">
              <h2 className="title-two">Experiencia y Aval en Calibración de Presión</h2>
              <p className="info-one">
                Nuestra acreditación P-36, otorgada por la entidad mexicana de
                acreditación, nos reconoce como un laboratorio competente para calibrar
                instrumentos como manómetros, balanzas de presión, transmisores y vacuómetros.
                Desde 9 Nov 2011 operamos bajo lineamientos internacionales y mantenemos vigencia
                indefinida con ratificación al 16 Oct 2024.
              </p>
              <p className="info-two">
                Calibrar con CalTechnix significa contar con el respaldo de un equipo altamente
                especializado, procesos auditados y acreditación ISO17025. Nos enfocamos en ofrecer
                resultados precisos, minimizando paros en producción y aumentando la confianza en sus
                mediciones críticas de presión.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default PresionServicePage;
