import React from "react";
import { Layout } from "../../layouts/Layout";
import { CHECK_ICON, SERVICES_DETAILS03 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const HumedadServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Calibración de humedad"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          {/* Imagen principal */}
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="Calibración de humedad" />
          </div>
          <div className="services-details-content">
            {/* TÍTULO PRINCIPAL */}
            <h2 className="title">Calibración en el área de humedad</h2>

            {/* DESCRIPCIÓN PRINCIPAL */}
            <p>
              Nuestro servicio de calibración de humedad está diseñado para garantizar
              mediciones precisas en dispositivos que controlan la humedad relativa y
              otros parámetros ambientales críticos. Utilizamos equipos especializados
              para comparar las lecturas de sus instrumentos con patrones de referencia
              certificados, asegurando resultados confiables y trazabilidad en cada
              calibración.
            </p>
            <p>
              Con procesos certificados bajo la norma ISO17025, ofrecemos calibraciones
              tanto en nuestro laboratorio como in situ, adaptándonos a las necesidades
              específicas de cada industria y reduciendo tiempos de inactividad.
            </p>

            {/* BLOQUE INTERNO */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                {/* COLUMNA IZQUIERDA */}
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      {/* PRIMER ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de sensores de humedad</h5>
                          <p>
                            Verificamos y ajustamos higrómetros, psicrómetros y otros
                            dispositivos, asegurando que cada instrumento ofrezca
                            resultados precisos y consistentes.
                          </p>
                        </div>
                      </li>
                      {/* SEGUNDO ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración en laboratorio y en planta</h5>
                          <p>
                            Ofrecemos la flexibilidad de realizar calibraciones tanto
                            en nuestras instalaciones como en el sitio del cliente,
                            adaptándonos a sus requerimientos específicos.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* COLUMNA DERECHA */}
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">¿Por qué elegir CalTechnix?</h3>
                    <p>
                      Nuestro equipo de metrólogos cuenta con amplia experiencia en la
                      calibración de instrumentos de humedad, respaldado por procesos
                      auditados y la acreditación ISO17025. Garantizamos la exactitud de
                      cada medición, optimizando el rendimiento de sus equipos y asegurando
                      la continuidad de sus operaciones.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Especialistas certificados en metrología ambiental
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Calibraciones trazables bajo norma ISO17025
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Servicio flexible en laboratorio y en planta
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="check icon" />
                        Resultados precisos que optimizan sus procesos
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* SECCIÓN DE EXPERIENCIA Y AVAL */}
            <div className="industrial-working-wrap">
              <h2 className="title-two">Experiencia y Aval en Calibración de Humedad</h2>
              <p className="info-one">
                Con una trayectoria comprobada, nuestra acreditación respalda la
                calibración de instrumentos críticos para el control ambiental. Desde
                higrómetros hasta sensores integrados en sistemas automatizados, garantizamos
                mediciones precisas y seguras.
              </p>
              <p className="info-two">
                Al confiar en CalTechnix, usted se beneficia de procesos auditados, un equipo
                técnico altamente especializado y un compromiso constante con la calidad.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default HumedadServicePage;
