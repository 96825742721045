// ServiceOne.jsx
import React from "react";
import { SERVICES_THREE_LIST } from "../../mocks/services";
import { ServiceHomeItem } from "./ServiceHomeItem";
import { SlickSlider } from "../SlickSlider/SlickSlider";
import { ReactTyped } from "react-typed";

export const ServicesHome = () => {
  const slick_settings_brands = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="services-area services-bg">
      <div className="container">
        {/* Encabezado */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title white-title text-center mb-50">
              <h2 className="title">
                Calibración.
              </h2>
              <br/>
              <h2 className="title mt-[-1.2rem]">
                Misión de&nbsp;
                <ReactTyped
                  strings={[
                    "innovación",
                    "confianza",
                    "compromiso",
                    "excelencia",
                    "calidad"
                  ]}
                  typeSpeed={130}
                  backSpeed={100}
                />
              </h2>
            </div>
          </div>
        </div>

        {/* Slider (o listado) */}
        <div className="row services-active">
          <SlickSlider settings={slick_settings_brands}>
            {SERVICES_THREE_LIST.map((service) => (
              <div key={service.id} className="col-lg-4">
                <ServiceHomeItem service={service} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  );
};
