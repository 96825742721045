import React from "react";
import { Layout } from "../../layouts/Layout";
import { ContactAreaInner } from "../../components/ContactAreas/ContactAreaInner";

const ContactPage = () => {
  return (
    <Layout header={1} breadcrumb={"Contacto"} title={"Contactanos"}>
      {/* contact-area */}
      <ContactAreaInner />
    </Layout>
  );
};

export default ContactPage;
