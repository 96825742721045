import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SERVICES_DETAILS03,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandServices } from "../../components/Brand/BrandServices";

const ElectricaServicePage = () => {
  return (
    <Layout header={1} breadcrumb={"Servicios"} title={"Calibración eléctrica"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            {/* TÍTULO PRINCIPAL */}
            <h2 className="title">Calibración en el área eléctrica</h2>

            {/* DESCRIPCIÓN PRINCIPAL */}
            <p>
              Nuestra especialidad en el servicio de eléctrica abarca la
              calibración y verificación metrológica de voltímetros,
              amperímetros, medidores de potencia y otros equipos de medición
              relacionados. El objetivo es ofrecer mediciones exactas que
              respalden la seguridad, la eficiencia de sus sistemas
              electromecánicos y el cumplimiento de normas de calidad.
            </p>
            <p>
              Al comparar los valores de su instrumento con patrones de
              referencia, garantizamos la confiabilidad de su sistema de
              medición. Bajo la acreditación ISO17025, ratificada por la entidad
              mexicana de acreditación (E-154), validamos cada calibración para
              brindarle la máxima trazabilidad y soporte técnico.
            </p>

            {/* BLOQUE INTERNO */}
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      {/* PRIMER ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración de instrumentos eléctricos</h5>
                          <p>
                            Verificamos la exactitud de voltímetros,
                            amperímetros, medidores de potencia, ohmímetros y
                            otros equipos esenciales para la medición de
                            parámetros eléctricos.
                          </p>
                        </div>
                      </li>

                      {/* SEGUNDO ELEMENTO DE LA LISTA */}
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Calibración en laboratorio y en planta</h5>
                          <p>
                            Ofrecemos calibraciones tanto en nuestro laboratorio
                            como en sus instalaciones, minimizando tiempos de
                            inactividad y garantizando el cumplimiento de sus
                            procesos.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* CONTENIDO ADICIONAL - DERECHA */}
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">¿Por qué elegir CalTechnix?</h3>
                    <p>
                      Nuestros especialistas en metrología cuentan con amplia
                      experiencia en la calibración eléctrica, respaldada por
                      reconocimientos y auditorías periódicas. Realizamos
                      procedimientos rigurosos siguiendo estándares
                      internacionales para asegurar la confiabilidad de sus
                      mediciones y la continuidad de sus operaciones.  
                      También utilizamos las últimas tecnologías para diagnosticar
                      y calibrar diferentes tipos de equipos, brindando asesoría
                      especializada según los requerimientos de cada industria.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Expertos avalados por la entidad mexicana de acreditación
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Equipos calibrados bajo norma ISO17025
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Procesos confiables y trazables
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Mayor seguridad operativa
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* SECCIÓN DE EXPERIENCIA Y AVAL */}
            <div className="industrial-working-wrap">
              <h2 className="title-two">Experiencia y Aval en Calibración Eléctrica</h2>
              <p className="info-one">
                Nuestra acreditación E-154, otorgada por la entidad mexicana de
                acreditación, nos reconoce como un laboratorio competente para
                calibrar medidores de tensión y corriente DC/AC, resistencia,
                capacitancia, potencia y más. Operamos bajo lineamientos
                internacionales con vigencia indefinida y ratificación al 16 Oct
                2024, demostrando nuestro compromiso con la calidad.
              </p>
            
              <p className="info-two">
                Calibrar con CalTechnix significa contar con el respaldo de un
                equipo experto, procesos auditados y acreditación ISO17025.
                Buscamos entregarle resultados precisos, evitando fallas y
                garantizando la continuidad de sus operaciones eléctricas más
                críticas.
              </p>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandServices />
    </Layout>
  );
};

export default ElectricaServicePage;
