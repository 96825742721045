import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

// ----- EJEMPLO DE ICONOS E IMÁGENES (podrías usar los tuyos) -----
import { FaCompressArrowsAlt, FaTemperatureHigh, FaBalanceScale, FaTools } from "react-icons/fa";
import { MdOutlinePlayForWork, MdElectricBolt } from "react-icons/md";
import { SERVICES_THREE_LIST } from "../../mocks/services";





// ----- SIDEBAR DINÁMICO -----
export const ServicesDetailsSidebar = ({
  hideContact = false,
  hideTitle = false,
}) => {
  const location = useLocation();

  // Definimos la lista de servicios para el menú lateral
  // (ajústala a tus necesidades; aquí unificamos con "/services/*")
  const servicesSidebar = [
    { label: "Calibración de presión", href: "/services/presion" },
    { label: "Calibración de fuerza", href: "/services/fuerza" },
    { label: "Calibración de par torsional (Torque)", href: "/services/par-torsional" },
    { label: "Calibración de temperatura", href: "/services/temperatura" },
    { label: "Calibración de masa", href: "/services/masa" },
    { label: "Calibración eléctrica", href: "/services/electrica" },
    { label: "Calibración de humedad", href: "/services/humedad" },
  ];

  // Obtenemos los datos del servicio actual buscando la ruta coincidente
  const currentServiceData = React.useMemo(() => {
    return SERVICES_THREE_LIST.find(
      (item) => item.href === location.pathname
    );
  }, [location.pathname]);

  return (
    <aside className="services-sidebar">
      {/* Nuestros Servicios */}
      {!hideTitle && (
        <div className="services-widget">
          <h4 className="sw-title">Nuestros servicios</h4>
          <div className="services-cat-list">
            <ul className="list-wrap">
              {servicesSidebar.map((item) => (
                <li
                  key={item.label}
                  className={cn({ active: location.pathname === item.href })}
                >
                  <Link to={item.href}>
                    {item.label} <i className="flaticon-right-arrow"></i>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {hideTitle && (
        <div className="services-cat-list mb-30">
          <ul className="list-wrap">
            {servicesSidebar.map((item) => (
              <li
                key={item.label}
                className={cn({ active: location.pathname === item.href })}
              >
                <Link to={item.href}>
                  {item.label} <i className="flaticon-right-arrow"></i>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Acreditación y Ratificación DINÁMICAS */}
      {currentServiceData && currentServiceData.accreditation && (
        <div className="services-widget">
          <h4 className="sw-title">Acreditación</h4>

          {/* Texto adicional sobre la acreditación */}
          <p style={{ marginBottom: "1rem" }}>
            {currentServiceData.accreditation.extraText}
          </p>

          {/* Enlaces a PDF de acreditación y ratificación */}
          <div className="services-brochure-wrap">
            <a
              href={currentServiceData.accreditation.accreditationLink}
              target="_blank"
              rel="noreferrer"
              className="download-btn"
            >
              <i className="far fa-file-pdf"></i> Ver PDF
            </a>
            <a
              href={currentServiceData.accreditation.ratificationLink}
              target="_blank"
              rel="noreferrer"
              className="download-btn"
            >
              <i className="far fa-file-alt"></i> Ratificación
            </a>
          </div>
        </div>
      )}

      {/* Teléfono de contacto (opcional) */}
      {!hideContact && (
        <div className="services-widget services-sidebar-contact">
          <h4 className="title">Si necesita ayuda, póngase en contacto con nosotros</h4>
          <a href="tel:5556504414">
            <i className="flaticon-phone-call"></i> +55 5650 4414
          </a>
        </div>
      )}
    </aside>
  );
};
