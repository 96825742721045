import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import { tg_title_animation } from "./lib/gsap/tg_title_animation";
import { useParticles } from "./lib/hooks/useParticles";
import { useParallax } from "./lib/hooks/useParallax";
import { useHasAnimation } from "./lib/hooks/useHasAnimation";
import { useWow } from "./lib/hooks/useWow";
import { useAos } from "./lib/hooks/useAos";
import { useJarallax } from "./lib/hooks/useJarallax";

import Home from "./pages/Home/Home";
import AboutPage from "./pages/About/AboutPage";
import ServicesPage from "./pages/Services/ServicesPage";
import PresionServicePage from "./pages/ServicesDetails/PresionServicePage";
import ElectricaServicePage from "./pages/ServicesDetails/ElectricaServicePage";
import FuerzaServicePage from "./pages/ServicesDetails/FuerzaServicePage";
import TemperaturaServicePage from "./pages/ServicesDetails/TemperaturaServicePage";
import MasaServicePage from "./pages/ServicesDetails/MasaServicePage";
import ParTorsionalServicePage from "./pages/ServicesDetails/ParTersorialServicePage";

import ProjectDetailsPage from "./pages/ProjectDetails/ProjectDetails";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import ContactPage from "./pages/Contact/ContactPage";

import ServiceOrderDetail from "./pages/ServiceOrder/ServiceOrderDetail";
import ServiceOrderDetailTwo from "./pages/ServiceOrder/ServiceOrderDetailTwo";
import PatternsPage from "./pages/Patterns/PattersPage";
import TraceabilityPage from "./pages/Traceability/TraceabilityPage";

import { tailChase } from 'ldrs'
import HumedadServicePage from "./pages/ServicesDetails/HumedadServicePage";

function App() {
  tailChase.register();
  useWow();
  useAos();
  useJarallax();
  useParallax();
  useParticles();
  useHasAnimation();

  // Animación de título y subtítulo
  useEffect(() => {
    tg_title_animation();
  }, []);

  // Al cambiar de ruta, vuelve al inicio de la página
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="services" element={<ServicesPage />} />
        <Route path="services/presion" element={<PresionServicePage />} />
        <Route path="services/electrica" element={<ElectricaServicePage />} />
        <Route path="services/fuerza" element={<FuerzaServicePage />} />
        <Route path="services/temperatura" element={<TemperaturaServicePage />} />
        <Route path="services/masa" element={<MasaServicePage />} />
        <Route path="services/par-torsional" element={<ParTorsionalServicePage />} />
        <Route path="services/humedad" element={<HumedadServicePage />} />
        <Route path="presion-service" element={<PresionServicePage />} />
        <Route path="patterns" element={<PatternsPage />} />
        <Route path="traceability" element={<TraceabilityPage />} />
        <Route path="OS/index.php" element={<ServiceOrderDetail />} />
        <Route path="service-order-search-v2/:idOrden" element={<ServiceOrderDetailTwo />} />
        <Route path="project-details" element={<ProjectDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
