import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
     <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.9771400605814!2d-99.11731352486824!3d19.37014248189597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fe6e7b990c69%3A0x7a273c4740161759!2sAv.%20R%C3%ADo%20Churubusco%20794%2C%20El%20Sif%C3%B3n%2C%20Iztapalapa%2C%2009400%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20M%C3%A9xico!5e0!3m2!1ses-419!2sus!4v1734933932579!5m2!1ses-419!2sus"
  allowfullscreen
  loading="lazy"
  title="contact map"
  style={{ borderRadius: "10px", border: "none", marginTop: "30px" }}
></iframe>
    </div>
  );
};
