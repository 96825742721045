import React from "react";
import {
  H4_ABOUT_IMG01,
  H4_ABOUT_IMG02,
} from "../../lib/assets";
import { Odometer } from "../Odometer/Odometer";
import { TbCertificate, TbTools } from "react-icons/tb";

export const AboutHome = () => {
  return (
    <section className="about-area-five">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className="about-img-wrap-five">
              <img src={H4_ABOUT_IMG01} alt="" />
              <img src={H4_ABOUT_IMG02} alt="" data-parallax='{"y" : 100 }' />
              <div className="experience-wrap">
                <h2 className="title">
                  32 <span>Años de experiencia</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-xl-7 col-lg-6">
            <div className="about-content-five">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  Nuestros clientes
                </span>
                <h2 className="title tg-element-title">
                  Empresas líderes, resultados garantizados
                </h2>
              </div>
              <p>
              Nuestro servicio abarca múltiples industrias, ayudando a empresas a cumplir con los estándares
              más rigurosos de calidad y precisión. 
              Desde laboratorios hasta gigantes de la automotriz, 
              nuestro compromiso con la excelencia es constante.
              </p>
              <div className="about-content-bottom">
                <div className="about-list">
                  <ul className="list-wrap">
                    <li>
                      <i className="fas fa-arrow-right"></i>Clientes nacionales e internacionales
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>Confianza de pequeñas y grandes empresas
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>Presencia en +20 Sectores Industriales
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>Soporte personalizado para cada cliente
                    </li>
                  </ul>
                </div>
                <div className="about-success-wrap">
                  <ul className="list-wrap">
                    <li>
                      <div className="icon">
                      <TbTools />
                      </div>
                      <div className="content">
                        <h2 className="count">
                          +<Odometer end={9800} />
                        </h2>
                        <p>Instrumentos calibrados al año</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                      <TbCertificate />
                      </div>
                      <div className="content">
                        <h2 className="count">
                          <Odometer end={99} />%
                        </h2>
                        <p>De satisfacción</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </section>
  );
};
